import React from 'react';
// import IconInbox from '@material-ui/icons/Inbox';
import IconInbox from '@material-ui/icons/FolderOpen';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList as List } from 'react-window';
// import { LIST_ARTICLES } from '../../graphql/queries';
import { queries } from '../../graphql';
import Modal from '../modal';
import Paginator from '../paginator';
import { toCanonical, removeDiacritics } from '../../resources/utils';

import './index.scss';

const DEFAULT_TITLE = "Sem título";
const TITLE_LIMIT = 60;
const DEFAULT_PAGE_SIZE = 30;
// const AUTHOR_LIMIT = 30;

let debouncedFilter = null;

function Open (props) {
  const title = 'Abrir';
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(1);
  const [articlesPerPage, setArticlesPerPage] = React.useState(DEFAULT_PAGE_SIZE);
  const [curPage, setCurPage] = React.useState(0);
  const [filteredList, setFilteredList] = React.useState([]);
  const filterRef = React.useRef('filterRef');
  // const filterTagsRef = React.useRef('filterTagsRef');
  const [currentFilter, setCurrentFilter] = React.useState('');
  // const filterAuthorRef = React.useRef('filterAuthorRef');
  
  React.useEffect(() => {
    goFetchTheList();
  }, [curPage, articlesPerPage, currentFilter]);

  async function goFetchTheList () {
    setLoading(true);
    if (!props.currentPublisher || !props.currentPublisher.id) {
      return;
    }

    return queries.getArticles(
      props.currentPublisher.id,
      props.settings.defaultLanguage.value,
      articlesPerPage,
      (articlesPerPage * curPage),
      currentFilter
    )
      .then(result => {
        // console.log(result.data.articles.nodes);
        const total = result.data.articles.totalCount;
        setTotalPages(Math.floor(total / articlesPerPage));
        const list = result.data.articles.nodes.map(item => {
          const content = item.contents.nodes.find(node => {
            return node.language === props.settings.defaultLanguage;
          }) || item.contents.nodes[0] || {};

          return {
            id: item.id,
            date: item.date,
            status: item.status,
            // author: item.author,
            tags: (item.tags || []).map(item => item.tag),
            title: (content ? content.title : null) || DEFAULT_TITLE,
            content
          };
        });

        setList(list);
        setFilteredList(list);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  function openArticle(articleId) {
    setLoading(true);
    queries.getArticleContent(props.currentPublisher.id, articleId)
    .then(async result => {
      const article = result.data.articles.article[0];
      article.contents = (article.contents.nodes || article.contents || []).map(content => {
        content.canonical = content.canonical || toCanonical(content.title);
        content.pages = (content.pages.nodes || content.pages || []).map(page => {
          page.blocks = (page.blocks.nodes || page.blocks || []).map(block => {
            block.data = typeof block.data === 'string' ? JSON.parse(block.data) : block.data;
            if (typeof block.data !== 'object') {
              console.error('Could not deal with block\'s content!', block);
              block.data = {};
            }
            return block;
          });
          return page;
        });
        return content;
      });
      article.tags = (article.tags || []).map(tagObj => {
        const tag = tagObj.tag;
        tag.canonical = tag.canonica || toCanonical(tag.name);
        return tag;
      });
      
      props.setCurrentArticle(article);
      setLoading(false);
      setOpen(false);
      props.setCurrentLang(props.settings.defaultLanguage);
    })
    .catch(err => {
      alert('Erro ao carregar documento!');
      console.error(err);
      setLoading(false);
      // setOpen(false);
    });
  }

  // function updateFilter () {
  //   let tmpList = [];
  //   const filterTitleVal = removeDiacritics(filterRef.current.value.toLowerCase());
  //   const filterTags = removeDiacritics(filterTagsRef.current.value.toLowerCase());
  //   // const filterAuthor = removeDiacritics(filterAuthorRef.current.value.toLowerCase());
  //   // const theFilter = new RegExp(`(${filterTitleVal})|(${filterTags})|(${filterAuthor})]`);

  //   if (filterTitleVal.trim().length || filterTags.trim().length ) { // || filterAuthor.trim().length) {
  //     list.forEach(item => {
  //       let shouldAdd = true;
  //       if (filterTitleVal) {
  //         if (!removeDiacritics(item.title || '').toLowerCase().includes(filterTitleVal)) {
  //           shouldAdd = false;
  //         }
  //       }
  //       // if (filterAuthor) {
  //       //   if (!item.author || !removeDiacritics(item.author.firstName + ' ' + item.author.lastName).toLowerCase().includes(filterAuthor)) {
  //       //     shouldAdd = false;
  //       //   }
  //       // }
  //       if (filterTags) {
  //         if (
  //           (!item.tags || !item.tags.length) ||
  //           !item.tags.map(tag => tag.name).join().toLowerCase().includes(filterTags)) {
  //             shouldAdd = false;
  //           }
  //       }
  //       if (shouldAdd) {
  //         tmpList.push(item);
  //       }
  //     });
  //   } else {
  //     tmpList = list;
  //   }
  //   setFilteredList(tmpList);
  // }

  function onFilterChange (event) {
    clearTimeout(debouncedFilter);
    debouncedFilter = setTimeout(_ => {
      setCurrentFilter(removeDiacritics(filterRef.current.value.toLowerCase()));
    }, 300);
  }

  return (
    <div>
      <ListItem
        button
        key={title}
        disabled={!props.currentPublisher}
        onClick={_=>{
          setOpen(true);
          setList(null);
          setLoading(true);
          goFetchTheList();
        }}>
        <ListItemIcon><IconInbox /></ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      <Modal
        title={title}
        loading={loading}
        maxWidth='xl'
        open={open}
        onClose={ _=> { setOpen(false); }}
        controlls={
          <Paginator
            curPage={curPage}
            pageSize={articlesPerPage}
            pageSizes={[15, 30, 60, 100]}
            numberOfPages={totalPages}
            onPageSizeChange={setArticlesPerPage}
            onChange={setCurPage}
          />
        }
        body={<div className="open-container">
          <div className="filters-container">
            <input
              type="text"
              ref={filterRef}
              placeholder="Filtrar pelo título"
              onChange={onFilterChange}
            />
            {/* <input
              type="text"
              ref={filterTagsRef}
              placeholder="Filtrar pela tag"
              onChange={onFilterChange}
            /> */}
            {/* <input
              type="text"
              ref={filterAuthorRef}
              placeholder="Filtrar por Autor"
              onChange={onFilterChange}
            /> */}
          </div>
          {
            (list && filteredList.length)
            ? <List
                height={250}
                itemCount={filteredList.length}
                itemSize={35}
                width={'100%'}
              >
                {
                  ({ index, style }) => {
                    const item = filteredList[index];
                    return <ListItem
                      button
                      style={style}
                      onClick={event => {
                        openArticle(item.id);
                      }}
                    >
                      <div className="open-list-item">
                        <div className="open-article-image">
                          {
                            item.content.image
                            ? <img src={item.content.image} />
                            : ''
                          }
                        </div>
                        <div>
                          {
                            (item.title).substring(0, TITLE_LIMIT)
                          }
                        </div>

                        <div className="open-tags-container">
                          {
                            (item.tags || []).map(tag => tag.name).join(', ')
                          }
                        </div>

                        {/* <div className="open-article-author-container">
                          {
                            item.author
                            ? ((item.author.firstName || '') + ' ' + (item.author.lastName || '')).substring(0, AUTHOR_LIMIT)
                            : ''
                          }
                        </div> */}

                        <div className="open-article-date-container">
                          {
                            item.date
                            ? new Intl.DateTimeFormat('pt-BR').format(new Date(item.date))
                            : ''
                          }
                        </div>
                      </div>
                    </ListItem>;
                  }
                }
              </List>
            : list
              ? <span>Nenhum artigo encontrado</span>
              : <span>Carregando lista...</span>
          }
        </div>}
      />
    </div>
  );
}

export default Open;
