let env = "PRD";
const _w = (typeof window === 'undefined') ? {location: {}} : window ;

if (_w.location.hostname === 'localhost') {
  env = 'LOCAL';
}

const defaultOpts = {
  dividePages: getStoredOptions('dividePages', 0),
  mediaGaleryType: getStoredOptions('mediaGaleryType', 'SINGLE'),
  // serverBaseURL: `${_w.location.protocol}//${_w.location.hostname}:8070/`,
  serverBaseURL: env === 'LOCAL'
    ? `${_w.location.protocol}//${_w.location.hostname}:8070/api/`
    : `${_w.location.protocol}//${_w.location.hostname}/api/`,
  // readerServerBaseURL: `https://reader.alright.com.br/`,
  readerServerBaseURL: window.REACT_APP_SERVER_ADDR + '/',
  defaultLanguage: getStoredOptions('defaultLanguage', { label: 'Portuguese', value: 'BR' }),
  defaultBehaviors: {
    onend: 'LOOP',
    flow: 'SEQUENCE'
  },
  languages: getStoredOptions ('languages', [
    { label: 'Portuguese', value: 'BR' },
    { label: 'Spanish', value: 'ES' },
    { label: 'English', value: 'EN' }
  ])
};

/**
 * Returns the previously stored value for a given option,
 * or a default value, if none.
 * 
 * @param {String} opt The option name
 * @param {Mixed} defaultOpts The default value to be used
 */
function getStoredOptions (opt, defaultOpts) {
  const stored = localStorage.getItem(opt);

  if (stored) {
    if (isNaN(stored)) {
      const firstChar = stored[0];
      if (firstChar === '"' || firstChar === '{' || firstChar === '[') {
        return JSON.parse(stored);
      }
    }
    return stored;
  }
  return defaultOpts;
}

function storeOptions (options = {}) {
  for (let optName in options) {
    let val = options[optName];
    val = typeof val === 'object' ? JSON.stringify(options[optName]) : val;
    localStorage.setItem(optName, val);
  }
}

export default defaultOpts;
export {
  getStoredOptions,
  storeOptions
}