const toCanonical = require('../resources/to-canonical');
const uniqId = require('uuid/v4');

function createArticle (settings = {}, opts = {}) {
  return {
    id: opts.id || uniqId(),
    tags: [],
    date: (new Date()).toISOString(),
    publisherId: opts.publisherId || null,
    source: '', // the url for the imported articles
    behaviors: {
      id: uniqId(),
      onend: 'LOOP',     // byCategory, random
      flow:  'SEQUENCE', // random
      media: 'SINGLE'
    },
    contents: [
      {
        id: uniqId(),
        title: opts.title || '',
        canonical: toCanonical(opts.title),
        language: settings ? settings.defaultLanguage.value : 'pt',
        pages: [{
          id: uniqId(),
          blocks: []
        }]
      }
    ]
  };
}

module.exports = createArticle;
