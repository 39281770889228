import React from 'react';

import NextIcon from '@material-ui/icons/NavigateNext';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';

import './index.scss';

export default function Paginator (props) {

  const DEFAULT_PAGE_SIZE = 25;
  const NUMBER_OF_PAGE_BUTTONS = 3;

  const {
    curPage,
    pageSize = DEFAULT_PAGE_SIZE,
    pageSizes = [10, 25, 50, 75, 100],
    numberOfPages = Math.ceil(numberOfPages),
    onPageSizeChange,
    onChange,
    label = "Itens por página"
  } = props;
  
  const pageButons = [];

  let firstOpt = curPage - (NUMBER_OF_PAGE_BUTTONS - 1);
  let diff = firstOpt < 0 ? Math.abs(firstOpt) : 0;
  let lastOpt = curPage + (NUMBER_OF_PAGE_BUTTONS -1) + diff;

  if (lastOpt > numberOfPages) {
    diff = numberOfPages - lastOpt;
    lastOpt = numberOfPages;
    firstOpt+= diff;
  }

  let firstBtn = Math.max(0, firstOpt);
  let lastBtn = Math.min(lastOpt, numberOfPages);

  // firstBtn = Math.min(firstBtn, numberOfPages - NUMBER_OF_PAGE_BUTTONS);
  // lastBtn = lastBtn - firstBtn;

  for (let i = firstBtn, l = lastBtn; i <= l; i++) {
    pageButons.push(<Button
      color="primary"
      variant="outlined"
      className="prev-next-btns"
      key={i}
      disabled={i === curPage}
      onClick={_ => onChange(i) }
    >
      {i + 1}
    </Button>);
  }

  return <div className="paginator-container">
    <div className="page-size-container">
      {label}&nbsp;
      <Select
        value={pageSize || pageSizes[0]}
        className="current-page-selector"
        name="currentPage"
        variant="outlined"
        onChange={event => {
          onPageSizeChange(event.target.value);
        }}
        labelWidth={50}
        fullWidth
        inputProps={{
          name: 'currentPage'
        }}
      >
        {
          pageSizes.map(size => <MenuItem value={size} key={size}>{size}</MenuItem>)
        }
      </Select>
    </div>

    <div className="current-page-controllers">
      <Button
        color="primary"
        variant="outlined"
        className="prev-next-btns"
        disabled={curPage < NUMBER_OF_PAGE_BUTTONS}
        onClick={_ => {
          onChange(0);
        }}
      >
        <FastRewindIcon /> 1&nbsp;
      </Button>
      <Button
        color="primary"
        variant="outlined"
        className="prev-next-btns"
        disabled={curPage === 0}
        onClick={_ => {
          let newPage = curPage - 1;
          if (newPage < 0) {
            newPage = 0;
          }
          onChange(newPage);
        }}
      >
        <PrevIcon />
      </Button>
      { pageButons }
      <Button
        color="primary"
        variant="outlined"
        className="prev-next-btns"
        disabled={!numberOfPages || curPage >= numberOfPages -1}
        onClick={_ => {
          let newPage = curPage + 1;
          if (newPage > numberOfPages) {
            newPage = numberOfPages;
          }
          onChange(newPage);
        }}
      >
        <NextIcon />
      </Button>
      <Button
        color="primary"
        variant="outlined"
        className="prev-next-btns"
        disabled={!numberOfPages || (curPage + NUMBER_OF_PAGE_BUTTONS) >= numberOfPages}
        onClick={_ => {
          onChange(numberOfPages);
        }}
      >
        &nbsp;{Math.floor((numberOfPages || 0) + 1)} <FastForwardIcon />
      </Button>
    </div>
  </div>;
}
