import React from 'react';
import Modal from '../modal';
import TextField from '@material-ui/core/TextField';

export default function LoginForm (props) {
  const [authenticating, setAuthenticating]     = React.useState(false);

  const {
    open,
    settings,
    onLogin
  } = props;

  const authenticate = () => {
    const user = document.getElementById('user-auth-ipt').value;
    const pwd = document.getElementById('pwd-auth-ipt').value;

    if (user.length && pwd.length) {
      setAuthenticating(true);
      fetch(
        `${settings.serverBaseURL}authenticate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain'
          },
          body: JSON.stringify({
            user,
            pwd
          })
        }
      ).then(async res => {
        const result = await res.json();
        setAuthenticating(false);
        if (result.status !== 'OK') {
          return alert(result.message || "Erro ao fazer autenticar!");
        }
        window.sessionStorage.setItem('currentUser', user);
        onLogin(result.data);
      }).catch(async error => {
        setAuthenticating(false);
        console.error('Failed authenticating!', error);
        return alert("Autenticação falhou!");
      });
    }
  }

  return <Modal
    title={"Login"}
    maxWidth='sm'
    open={open}
    loading={authenticating}
    // onClose={_ => {  }}
    onSave={authenticate}
    locked
    saveLabel="Entrar"
    body={<div>
      <TextField
        id="user-auth-ipt"
        label="Usuário"
        variant="outlined"
        maxLength="40"
        fullWidth
        autoFocus
      />
      <TextField
        id="pwd-auth-ipt"
        label="Senha"
        type="password"
        variant="outlined"
        maxLength="40"
        fullWidth
        autoFocus
        style={{
          marginTop: '10px'
        }}
      />
    </div>}
  />;
}
