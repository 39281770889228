import React from 'react';
import IconRssFeed from '@material-ui/icons/RssFeed';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FixedSizeList as List } from 'react-window';
import { mutations } from '../../graphql';
import { Logger } from '../../resources/utils';

import Modal from '../modal';
import './index.scss';

function Import (props) {
  const logger = new Logger('Import Tool');
  const title = 'Importar';
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [contentList, setContentList] = React.useState(null);
  const [values, setValues] = React.useState({
    url: '',
    type: 'feed',
  });
  
  const handleChange = event => {
    const target = event.target;

    if (target && target.name) {
      setValues(oldValues => ({
        ...oldValues,
        [target.name]: target.value,
      }));
    }
  };

  const onSave = (event, url = null) => {
    let type = null;
    if (url) {
      type = 'page';
    } else {
      url = values.url;
    }

    if (url.length) {
      url = url || values.url;
      type = type || values.type;

      setLoading(true);
      fetch(
        `${props.settings.serverBaseURL}import`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain'
          },
          body: JSON.stringify({
            settings: {
              dividePages: props.settings.dividePages,
              languages: props.settings.languages,
              defaultLanguage: props.settings.defaultLanguage
            },
            url,
            type
          })
        })
        .then(async res => {
          logger.info('Retrieved result');
          const result = await res.json();
          
          if (result.status !== 'OK') {
            logger.error('The status was not OK:', result.message, result);
            throw new Error(result.message);
          }
          logger.info('Status was ok');
          
          const data = result.data;

          if (data.contents.length > 1) {
            logger.info('We received a list of articles');
            // we got a list of contents (pages)
            setContentList(data.contents);
          } else {
            logger.info('We received an article');
            data.publisher = props.currentPublisher;
            data.date = (new Date()).toISOString();
            
            props.setSaving(true);
            logger.info('Saving the article in background');
            mutations.createArticle(data)
              .then(_ => {
                logger.info('Article created in the database');
                props.setSaving(false);
              })
              .catch(error => {
                alert('Falha ao salvar o arquivo importado!');
                logger.error(error);
                props.setSaving(false);
              });

            logger.info('Opening the imported article');
            props.setCurrentArticle(data);
            setContentList(null);
            setOpen(false);
            logger.info('Reseting to the default language');
            props.setCurrentLang(props.settings.defaultLanguage);
          }
          setLoading(false);
        })
        .catch(error => {
          alert('Ocorreu um erro durante a importação!\n' + (error.message || error.toString()))
          logger.error('Failed trying to import!', error);
          setLoading(false);
        });
    }
  };
  
  React.useEffect(() => {
  }, []);

  return (
    <>
      <ListItem
        button
        key={title}
        disabled={!props.currentPublisher}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><IconRssFeed /></ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>

      <Modal
        title={title}
        body={
          contentList
          ? <List
              height={250}
              itemCount={contentList.length}
              itemSize={35}
              width={'100%'}
            >
              {
                ({ index, style }) => (
                  <ListItem
                    button
                    style={style}
                    onClick={event => {
                      onSave(event, contentList[index].link);
                    }}
                  >
                    {contentList[index].title}
                  </ListItem>
                )
              }
            </List>
          : <div>
              Você pode importar o conteúdo de uma página ou artigo específicos, ou carregar de uma lista de artigos vindos de um Feed RSS ou Atom.
              <TextField
                // id="outlined-name"
                label="URL"
                fullWidth
                // name="url"
                onChange={handleChange}
                disabled={loading}
                inputProps={{
                  name: 'url',
                  type: 'url',
                  placeholder: 'https://page.com/feed',
                  autoComplete: 'off'
                }}
                // className={classes.textField}
                value={values.url}
                margin="normal"
                variant="outlined"
              />

              <FormControl
                variant="outlined"
                // className={classes.formControl}
                style={{width: '200px'}}
              >
                <InputLabel
                  // ref={inputLabel}
                  htmlFor="outlined-age-simple">
                  Tipo
                </InputLabel>
                <Select
                  value={values.type}
                  name="type"
                  onChange={handleChange}
                  disabled={loading}
                  labelWidth={50}
                  inputProps={{
                    name: 'type'
                  }}
                >
                  <MenuItem value='feed'>Feed (RSS/Atom)</MenuItem>
                  <MenuItem value='page'>Artigo único</MenuItem>
                </Select>
              </FormControl>
            </div>
          }
        maxWidth='sm'
        loading={loading}
        open={open}
        onSave={contentList ? null : onSave}
        saveLabel='Buscar'
        onClose={_=>{
          setOpen(false);
          setContentList(null);
        }}
      />
    </>
  );
}

export default Import;
