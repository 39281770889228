import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// import { withStyles } from '@material-ui/core/styles';

import LogoutIcon from '@material-ui/icons/MeetingRoom';
import Modal from '../modal';
import './index.scss';

function Logout (props) {
  const title = 'Sair';
  const [open, setOpen] = React.useState(false);
  
  React.useEffect(() => {
  }, []);

  return (
    <>
      <ListItem
        button
        key={title}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      <Modal
        title={title}
        body={<div>
          Tem certeza que deseja deslogar?
        </div>}
        maxWidth='sm'
        cancelLabel="Não"
        saveLabel="Sim"
        onSave={props.logout}
        open={open}
        onClose={_=>{setOpen(false)}}
      />
    </>
  );
}

export default Logout;
