import React from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import settings from '../../resources/default-settings';

const uploadSignature = (_callback, _paramsToSign) => {
  fetch(`${settings.serverBaseURL}signature`, {
    method: 'POST',
    body: JSON.stringify({ params: _paramsToSign }),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
    .then(res => res.json())
    .then(({ signature }) => {
      _callback(signature);
    })
    .catch(err => {
      console.log(`upload_signature error: ${err.message}`);
    });
};

const UploadButton = ({ text, icon: Icon, folder, setURL, inline = null, open = false, button = null }) => {
  const language = navigator.languages;

  React.useEffect(_ => {
    if (open) {
      showWidget();
    }
  }, []);

  function showWidget() {
    try {
      let widget = window.cloudinary.openUploadWidget({
        apiKey: process.env.REACT_APP_CLOUDINARY_API_KEY,
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        sources: [
          "local",
          "url",
          "dropbox",
          "image_search",
          "facebook",
          "instagram"
        ],
        googleApiKey: process.env.REACT_APP_GOOGLE_SEARCH_KEY,
        cropping: true,
        multiple: false,
        searchBySites: ["all", "cloudinary.com"],
        searchByRights: true,
        resourceType: "image",
        inlineContainer: inline || null,
        clientAllowedFormats: ["jpg", "jpeg", "png", "gif"],
        folder,
        uploadPreset: 'images',
        upload_signature: uploadSignature,
      }, (err, result) => {
        if (err) {
          console.log(`open_upload_widget error: ${JSON.stringify(err)}`);
        }
        if (!err && result && result.event === "success") {
          setURL(result.info.secure_url);
          widget.close();
        }
      });

    } catch (err) {
      console.log(`general error: ${err.message}`);
    }
  }

  return (
    button
      ? <span onClick = { showWidget }>{button}</span>
      : <Button
          variant = "contained"
          color = "default"
          startIcon = { Icon?<Icon/> : <CloudUploadIcon/ >}
          onClick = { showWidget }
        >
          <span>
            {
              text
                ? <>{text}</>
                : <>{language[0] === 'en-US' ? 'Upload' : 'Alterar'}</>
            }
          </span>
        </Button >
    );
};

export default UploadButton;
