import React /*, { useEffect, useState, useCallback, useRef }*/ from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ApplyIcon from '@material-ui/icons/PlayArrow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Modal from '../../modal';
import Block from '../index.js';

import './index.scss';

/**
 * React component: EmbedBlock
 * 
 * A block of type "embed" allows the user to save any kind of source code or iframe.
 * 
 * @param {Object} props Component's properties
 * @return {ReactComponent} EmbedBlock
 */
export default function EmbedBlock (props) {

  const container = React.useRef(null);
  
  const [editing, setEditing] = React.useState(false);
  const [name, setName] = React.useState("");
  const [content, setContent] = React.useState({
    elements: "",
    scripts: ""
  });
  
  React.useEffect(_ => {
    const contents = props.data.content;
    if (typeof contents === 'string') {
      setContentAndScripts(contents);
    } else {
      setContent({
        elements: (contents ? contents.elements : "") || "",
        scripts: (contents ? contents.scripts : "") || ""
      });
    }
    setName(props.data.name);
  }, [props.data]);

  function onInfo () {
    return <span>Campos de navegação, para página anterior ou próxima</span>
  }

  function onEdit () {
    setEditing(true);
  }

  function onEdited () {
    props.onEdit({ name, content });
    setEditing(false);
  }

  function setContentAndScripts (string) {
    const content = document.createElement('div');
    const scripts = document.createElement('div');
    content.innerHTML = string;
    Array.from(content.querySelectorAll('script')).forEach(scr => {
      scripts.appendChild(scr);
    });
    setContent({
      elements: content.innerHTML,
      scripts: scripts.innerHTML
    });
  }

  function applyEmbed () {
    // debugger;
    const iframe = document.createElement('iframe');
    container.current.firstElementChild.replaceWith(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(content.elements);
    iframe.contentWindow.document.write(content.scripts);
    iframe.contentWindow.document.close();

    iframe.onload = function () {
      const limit = 100;
      let timer = 0;
      let curSize = [];
      let everythingLoaded = setInterval(function() {
        timer++;
        if (timer > limit) {
          clearInterval(everythingLoaded);
          return;
        }

        try {
          const doc = iframe.contentWindow.document.body;

          if (curSize[0] !== doc.scrollWidth) {
            curSize[0] = doc.scrollWidth;
            iframe.width = curSize[0];
          }
          if (curSize[1] !== doc.scrollHeight) {
            curSize[1] = doc.scrollHeight;
            iframe.height = curSize[1];
          }
        } catch (error) {
          console.warn(error);
        }
      }, 100);
    }
  }

  return <>
    <Block
      {...props}
      onInfo={onInfo}
      onEdit={onEdit}
      editOnDblClick
    >
      <div className='block-embed-container'>
        <div className="header">
          <div className="title-container">
            <strong>Embeded: </strong>{props.data.name || "Sem nome"}<br/>
          </div>
          <div className="play-btn-container">
            <IconButton
              color="inherit"
              onClick={applyEmbed}
            >
              <ApplyIcon />
            </IconButton>
          </div>
        </div>
        <div className="embeded-short-content">
          {
            (
              typeof props.data.content === 'string'
                ? props.data.content || ""
                : props.data.content.elements
            ).substring(0, 140) + '...'
          }
        </div>
        <div className="embeded-content" ref={container} >
          <div />
        </div>
      </div>
    </Block>
    {
      editing
      ? <Modal
          title='Editando...'
          body={<div className="block-embed-modal-container">
            <TextField
              // id="outlined-name"
              label="Nome"
              // name="url"
              fullWidth
              onChange={event => { setName(event.target.value); }}
              // disabled={loading}
              inputProps={{
                // name: 'url',
                // type: 'url',
                placeholder: '',
                autoComplete: 'off'
              }}
              // className={classes.textField}
              value={name}
              margin="normal"
              variant="outlined"
            />
            <TextareaAutosize
              className="the-textarea"
              aria-label="Cole aqui o conteúdo a ser embedado"
              rowsMin={3}
              placeholder="Cole aqui, o conteúdo a ser embedado"
              value={content.elements + content.scripts}
              onChange={event => setContentAndScripts(event.target.value)}
            />
          </div>}
          maxWidth='md'
          onSave={onEdited}
          open={!!editing}
          saveLabel="Aplicar"
          onClose={_=>{setEditing(false)}}
        />
      : null
    }
  </>;
}
