
function toCanonical (str, sep = '-') {
  return str.normalize('NFD')        // replace all the accentuation
    .replace(/[\u0300-\u036f]/g, "") // replace special characters
    .toLowerCase()                   // it should be in lower case
    .replace(/[ \t\n]/g, sep)        // replacing all spaces, tabs and possible new lines as well
    .replace(/-+/g, sep)             // removing any multiple dash that might have ended in the string
    .replace(/\&/g, 'e')             // let's give a meaning to the & character
    .replace(/[^a-z0-9_-]/g, '');    // anything else that is not a letter or a number
}

module.exports = toCanonical;
