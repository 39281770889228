import React from 'react';
import Popper from '@material-ui/core/Popper';

import Paper from '@material-ui/core/Paper';
// import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TranslateIcon from '@material-ui/icons/TranslateOutlined';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import GTranslateIcon from '@material-ui/icons/GTranslateOutlined';
import NewIcon from '@material-ui/icons/CreateNewFolderOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createLanguage, cloneLanguate } from '../../resources/data-structures';
import Modal from '../modal';
import './index.scss';

function Translations (props) {
  const modalTitle = 'Traduções';
  // const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [translationModalOpen, setTranslationModalOpen] = React.useState(false);
  
  React.useEffect(() => {
  }, []);

  function setCurLang (lang) {
    const done = props.setCurrentLang(lang);
    if (!done) {
      // language not available for the current article
      setTranslationModalOpen(lang);
    }
  }

  function createContentForLang (language) {
    const title = (props.article.contents.find(c => c.language === props.settings.defaultLanguage.value) || {}).title || '';
    props.article.contents.push(createLanguage(props.settings, { title, language: language.value }));
    props.setCurrentArticle(props.article);
    setLoading(true);
    setTimeout(_ => {
      setTranslationModalOpen(false);
      setCurLang(language);
      setLoading(false);
    }, 500);
  }

  function cloneFromCurrent (language, useDefault=false, passive=false) {
    // use the current language, or else, the default one
    const curLang = props.currentLang.value;
    const { settings, article } = props;
    const contentToBeCloned = (useDefault ? article.contents.find(c => c.language === settings.defaultLanguage.value) : false)
      || article.contents.find(c => c.language === curLang)
      || null;

    const clone = cloneLanguate(settings, curLang, contentToBeCloned);

    if (passive) {
      return clone;
    }

    article.contents.push(clone);

    props.setCurrentArticle(props.article);

    setLoading(true);
    setTimeout(_ => {
      setTranslationModalOpen(false);
      setCurLang(language);
      setLoading(false);
    }, 500);
  }

  function autoTranslate (language) {
    setLoading(true);
    const clone = cloneFromCurrent(language, true, true);
    const { settings, article } = props;
    // const contentIdx = article.contents.findIndex(c => c.language === language.value);
    // const content = article.contents[contentIdx];

    fetch(
      `${settings.serverBaseURL}translate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          from: props.settings.defaultLanguage.value,
          to: language.value,
          content: clone
        }) 
      })
      .then(async res => {
        const result = await res.json();
        
        if (result.status !== 'OK') {
          throw new Error(result.message);
        }

        const data = result.data;
        // article.contents[contentIdx] = data;

        article.contents.push(data);
        props.setCurrentArticle(props.article);

        props.setCurrentArticle(article);
        setTimeout(_ => {
          setCurLang(language);
          setTranslationModalOpen(false);
          setLoading(false);
        }, 500);
      }).catch(error => {
        alert('Não foi possível fazer a tradução automática!');
        console.error(error);
        setLoading(false);
      });
  }

  return (<>
    <ClickAwayListener onClickAway={_=>{setAnchorEl(null);}}>
      <div>
        <ListItem
          button
          key={modalTitle}
          ref={anchorEl}
          onClick={event => {
            setAnchorEl(prev => (prev ? null : event.currentTarget));
          }}>
          <ListItemIcon><TranslateIcon /></ListItemIcon>
          <ListItemText primary={modalTitle} />
        </ListItem>
        <Popper open={!!anchorEl} anchorEl={anchorEl} placement="right">
          {({ TransitionProps }) => (
            <Paper className="translation-pop-container">
              {
                props.settings.languages.map(lang => {
                  return <ListItem
                      button
                      key={lang.value}
                      selected={lang.value === props.currentLang.value}
                      onClick={_ => {
                        setCurLang(lang);
                      }}
                    >
                      <div className="lang-acronym">{lang.value.substring(-2)}</div>
                      <ListItemText primary={lang.label} />
                    </ListItem>;
                })
              }
            </Paper>
          )}
        </Popper>

      </div>
    </ClickAwayListener>
    <Modal
      title={modalTitle}
      maxWidth='sm'
      open={!!translationModalOpen}
      onClose={_=>{setTranslationModalOpen(false)}}
      loading={loading}
      body={<div>
        <div className="disclaimer">
          O idioma <span className="lang-name">{translationModalOpen.value}</span> não existe para este conteúdo, gostaria de cria-lo?<br/>
          Para isso, escolha uma das opções abaixo:
        </div>
        <div className="translation-options">
          <div className="trans-option" onClick={_ => cloneFromCurrent(translationModalOpen)}>
            <CopyIcon /> <span>Clonar do idioma atual</span>
          </div>
          <div className="trans-option" onClick={_ => autoTranslate(translationModalOpen)}>
            <GTranslateIcon /> <span>Traduzir automaticamente</span>
          </div>
          <div className="trans-option" onClick={_ => createContentForLang(translationModalOpen)}>
            <NewIcon /> <span>Iniciar conteúdo do zero, para o idioma</span>
          </div>
        </div>
      </div>}
    />
  </>);
}

export default Translations;
