import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Publish from '../publish';
import Remove from '../remove';
import Preview from '../preview';
import Categories from '../categories';
import Behaviors from '../behaviors';
import ArticleInfo from '../article-info';
import Import from '../import';
import New from '../new';
import Open from '../open';
import Gallery from '../gallery';
import Verticals from '../verticals';
import Settings from '../settings';
import ChangePublisher from '../change-publisher';
import Logout from '../logout';
import Help from '../help';

import './index.scss';
import Translations from '../translations';

const drawerWidth = 320;
const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

function Sidebar(props) {
  const classes = useStyles();
  const [controlVerticals, setControlVerticals] = useState(false);

  const {
    open,
    settings,
    current,
    setCurrentArticle
   } = props;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className="sidebar-drawer">
        <Collapse in={!!current}>
          <List className="sidebar-menu-icons-container">
            <Publish
              hasChange={props.hasChange}
              publishArticle={props.publishArticle}
            />
            <Preview
              settings={settings}
              currentPublisher={props.currentPublisher}
              article={props.current}
            />
            <Behaviors
              onSave={props.onBehaviorsChange}
              settings={settings}
              setSaving={props.setSaving}
              behaviors={props.current ? props.current.behaviors : {}}
            />
            <Categories
              currentPublisher={props.currentPublisher}
              onSave={props.onCategoriesChange}
              setSaving={props.setSaving}
              tags={props.current ? props.current.tags : []}
              verticals={controlVerticals}
            />
            <Translations
              settings={settings}
              currentLang={props.currentLang}
              setCurrentArticle={setCurrentArticle}
              article={props.current}
              setSaving={props.setSaving}
              setCurrentLang={props.setCurrentLang}
            />
            <ArticleInfo
              settings={settings}
              currentLang={props.currentLang}
              currentPublisher={props.currentPublisher}
              setCurrentArticle={setCurrentArticle}
              setSaving={props.setSaving}
              article={props.current}
            />
            <Remove
              removeArticle={props.removeArticle}
              setSaving={props.setSaving}
            />
          </List>
          <Divider />
        </Collapse>
        <List className="sidebar-menu-icons-container">
          {
            props.isAdmin
            ? <ChangePublisher
                currentUser={props.currentUser}
                currentPublisher={props.currentPublisher}
                updatePublisher={props.updatePublisher}
                setCurrentPublisher={props.setCurrentPublisher}
                settings={settings}
                setCurrentArticle={setCurrentArticle}
                setCurrentLang={props.setCurrentLang}
              />
            : null
          }
          <New
            currentPublisher={props.currentPublisher}
            settings={settings}
            setCurrentArticle={setCurrentArticle}
            setCurrentLang={props.setCurrentLang}
            setSaving={props.setSaving}
          />
          <Import
            currentPublisher={props.currentPublisher}
            settings={settings}
            setCurrentArticle={setCurrentArticle}
            setCurrentLang={props.setCurrentLang}
            setSaving={props.setSaving}
          />
          <Open
            currentPublisher={props.currentPublisher}
            settings={settings}
            setCurrentArticle={setCurrentArticle}
            setCurrentLang={props.setCurrentLang}
          />
          <Gallery
            currentPublisher={props.currentPublisher}
            settings={settings}
            setCurrentArticle={setCurrentArticle}
            setCurrentLang={props.setCurrentLang}
          />
          <Verticals
            currentPublisher={props.currentPublisher}
            current={current}
            setVerticals={setControlVerticals}
          />
          <Settings
            settings={settings}
            setSaving={props.setSaving}
          />
          <Divider />
          <Logout logout={props.logout} />
          <Help />
        </List>
      </div>
    </Drawer>
  );
}

export default Sidebar;
