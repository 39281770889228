import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconCategory from '@material-ui/icons/CategoryOutlined';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import uniqId from 'uuid/v4';
import { queries, mutations } from '../../graphql';

import { toCanonical } from '../../resources/utils';

import Modal from '../modal';
import './index.scss';
import useStyles from './styles.js';

function ConfirmationDialogRaw(props) {
  const classes = useStyles();
  const { onClose, value: valueProp, open, options, ...other } = props;

  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Selecione uma nova vertical de destino para mover os artigos</DialogTitle>
      <DialogContentText id="confirmation-dialog-description" className={classes.dialogDescription}>
        * Esta ação pode demorar alguns minutos
      </DialogContentText>
      <DialogContent dividers>
        {options.length > 0 ? 
          <RadioGroup
            ref={radioGroupRef}
            aria-label="tags-select-menu-name"
            name="tags-select-menu-name"
            value={value}
            onChange={handleChange}
          >
            {options.map(({id, name}) => (
              <FormControlLabel value={id} key={id} control={<Radio />} label={name} />
            ))}
          </RadioGroup>
        : 
        <DialogContentText id="confirmation-dialog-description" className={classes.dialogDescription}>
          Lista vazia!<br/>
          Por favor, adicione uma nova vertical para mover os artigos
        </DialogContentText>}      
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        {options.length > 0 && 
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        }      
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  currentTagUUID: PropTypes.string.isRequired,
};

function Verticals ({ currentPublisher, current, setVerticals }) {
  const classes = useStyles();
  const modalTitle = 'Verticais';

  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [newValue, setNewValue] = useState('');

  const [openOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [valueToDelete, setValueToDelete] = useState({ currentUUID: '', newUUID: '' });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!currentPublisher || typeof currentPublisher === 'string') {
      return;
    }
    getAllTagsEditorship();
  }, [currentPublisher]);

  useEffect(() => {
    if(valueToDelete && valueToDelete.currentUUID && valueToDelete.newUUID) {
      const { currentUUID, newUUID } = valueToDelete;
      moveArticlesAndDeleteCurrentUUID(currentUUID, newUUID);
    }
  }, [valueToDelete]);

  const moveArticlesAndDeleteCurrentUUID = async (currentUUID, newUUID) => {
    try {
      await mutations.moveArticlesFromCurrentToNewTag(currentUUID, newUUID);
      await mutations.deleteTagEditorship(currentUUID);

      getAllTagsEditorship();
      updateCategories();
      setValueToDelete({ currentUUID: '', newUUID: '' });

    } catch (error) {
      console.log(error.message);
      alert("Ocorreu um erro ao tentar mover artigos para a nova vertical");
      setValueToDelete({ currentUUID: '', newUUID: '' });
      return;
    }
  };

  const getAllTagsEditorship = () => {
    queries.getAllTagsEditorship(currentPublisher.id)
    .then(tagsList => {
      if (tagsList.data && tagsList.data.tags) {
        setTags(tagsList.data.tags.nodes);
      }
    })
  };

  const updateCategories = () => {
    if(current) setVerticals(state => !state);
  };

  const handleAddVertical = (value) => {
    if(!value || value.trim().length === 0) {
      alert('Campo vazio!');
      return;
    }

    if(tags.some(tag => tag.name === value.trim())) {
      alert(`Já existe uma vertical chamada "${value.trim()}"!`);
      return;
    }

    if (window.confirm(`Deseja adiconar a vertical "${value.trim()}"?`)) {
      const tagEditorship = {
        id: uniqId(),
        name: value.trim(),
        kind: "EDITORSHIP",
        canonical: toCanonical(value.trim()),
        publisherId: currentPublisher.id
      }
      mutations.addTagEditorship(tagEditorship)
      .then(({data : {upsertTag : { tag } } }) => {
        setTags([...tags, tag]);
        updateCategories();
        setNewValue('');
        return;
      }).catch(error => {
        console.log(error.message);
        alert(`Ocorreu um erro tentar adicionar a vertical "${value.trim()}"`);
        return;
      });
    }
  };

  const handleDeleteVertical = (tagID, value) => {
    if (window.confirm(`Deseja remover a vertical "${value}"?`)) {
      mutations.deleteTagEditorship(tagID)
      .then(_ => {
        getAllTagsEditorship();
        updateCategories();
      })
      .catch(error => {
        if(error.message.match(/foreign key|article_tag/i)) {
          alert(`Atenção! Esta vertical "${value}" possui artigos relacionados!`);
          setOptions(tags.filter(({id}) => id !== tagID));
          handleOpenOptionsList();
          setValueToDelete({ currentUUID: tagID, newUUID: valueToDelete.newUUID })
          return;
        }
        alert(`Ocorreu um erro ao deletar a vertical "${value}"`);
        return;
      });
      return;
    }
  };

  const handleUpdateVerticalName = (tagID, value) => {
    const newVerticalName = prompt("Por favor, insira o novo valor", value);
    if (!newVerticalName || newVerticalName.trim().length === 0) {
      alert('Campo vazio!');
      return;
    }

    if (window.confirm(`Deseja alterar a vertical "${value}" por "${newVerticalName.trim()}"?`)) {
      mutations.updateTagEditorship(tagID, newVerticalName.trim())
      .then(({data : {updateTag : { tag } } }) => {
        const newListTags = tags.filter(currentTag => currentTag.name !== value);
        setTags([...newListTags, tag]);
        updateCategories();
        return;
      })
      .catch(error => {
        console.log(error.message);
        alert(`Ocorreu um erro ao alterar a vertical "${value}"`);
        return;
      });
    }
  };

  const handleChangeValue = useCallback((event) => {
    const { value } = event.target;
    setNewValue(value);
  },[]);

  const handleClearInput = useCallback(() => {
    setNewValue('');
  },[]);

  const handleOpenOptionsList = () => setOpenDeleteDialog(true);

  const handleClose = (valueSelected) => {
    setOpenDeleteDialog(false);

    if (valueSelected) {
      setValueToDelete({ currentUUID: valueToDelete.currentUUID, newUUID: valueSelected });
    }
  };

  return (
    <div>
      <ListItem
        button
        key={modalTitle}
        disabled={!currentPublisher}
        onClick={_=> setOpen(state => !state)}>
        <ListItemIcon><IconCategory /></ListItemIcon>
        <ListItemText primary={modalTitle} />
      </ListItem>
      <Modal
        title={modalTitle}
        body={
          <>
          <Paper component="form" className={classes.root}>
            <InputBase
              id="input-vertical"
              className={classes.input}
              placeholder="Nova vertical"
              value={newValue}
              onChange={e => handleChangeValue(e)}
            />

            <Divider className={classes.divider} orientation="vertical" />
            <IconButton 
              color="primary" 
              className={classes.iconButton} 
              aria-label="Adicionar" 
              title="Adicionar"
              onClick={() => handleAddVertical(newValue)}
            >
              <AddIcon />
            </IconButton>
            <IconButton 
              className={classes.iconButton} 
              aria-label="Limpar"
              title="Limpar"
              onClick={handleClearInput}
            >
              <ClearIcon />
            </IconButton>

            <ConfirmationDialogRaw
              classes={{
                paper: classes.paper,
              }}
              id="tags-select-menu"
              keepMounted
              open={openOpenDeleteDialog}
              onClose={handleClose}
              value={valueToDelete}
              options={options}
            />
          </Paper>

          <br/>

          <List>
            {tags && tags.length > 0 && tags.map(({id, name}) => (
            <ListItem key={id}>
              <ListItemText primary={name} />
              <ListItemSecondaryAction>
                <IconButton 
                  edge="end" 
                  aria-label="update" 
                  onClick={() => handleUpdateVerticalName(id, name)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  edge="end" 
                  aria-label="delete" 
                  onClick={() => handleDeleteVertical(id, name)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            ))}
          </List>
          <br/>
          </>
        }
        maxWidth='sm'
        open={open}
        onClose={_=> setOpen(false)}
      />
    </div>
  );
}

export default Verticals;
