import uniqid from 'uuid/v4';
import createArticle from './article-template.js';

function createPage () {
  return {
    id: uniqid(),
    blocks: []
  }
}

function createLanguage (settings, opts = {}) {
  return {
    id: uniqid(),
    title: opts.title || '',
    image: opts.image,
    // images: [...(opts.images || [])],
    language: opts.language || settings.defaultLanguage.value,
    pages: [{
      id: uniqid(),
      blocks: []
    }]
  };
}

function cloneLanguate (settings, language, toBeCloned) {
  // fast deep clone
  const clone = JSON.parse(JSON.stringify(toBeCloned));
  clone.id = uniqid();
  clone.language = language;
  clone.pages.forEach(page => {
    page.id = uniqid();
    page.blocks.forEach(block => {
      block.id = uniqid();
    });
  });

  return clone;
}

export {
  createArticle,
  createPage,
  createLanguage,
  cloneLanguate
};
