import React from 'react';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import './index.scss';

function Title (props) {

  const [ editing, setEditing ] = React.useState(false);
  const [ editingTophat, setEditingTophat ] = React.useState(false);
  const inputRef = React.useRef(null);
  const inputTophatRef = React.useRef(null);

  function onEdit () {
    // const contentHTML = convertFromHTML(props.content);
    // const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
    // const content = JSON.stringify(convertToRaw(state));
    setEditing(true);
  }

  function onTophatEdit () {
    setEditingTophat(true);
  }

  function onEdited (event) {
    const newTitle = inputRef.current.querySelector('input').value;
    props.onEdit(newTitle);
    setEditing(false);
  }

  function onTophatEdited (event) {
    const newTophat = inputTophatRef.current.querySelector('input').value;
    props.onTophatEdit(newTophat);
    setEditingTophat(false);
  }

  function onTitleKeyDown (event) {
    if (event.keyCode === 13 && editing) {
      // enter
      onEdited();
    }
    if (event.keyCode === 27) {
      setEditing(false);
    }
  }

  function onTophatKeyDown (event) {
    if (event.keyCode === 13 && editingTophat) {
      // enter
      onTophatEdited(event);
    }
    if (event.keyCode === 27) {
      setEditingTophat(false);
    }
  }

  return <>
    <h2>
      {
        editing
        ? <Input
            fullWidth
            defaultValue={props.content}
            ref={inputRef}
            autoFocus
            onKeyDown={onTitleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onEdited}>
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={_=>setEditing(false)}>
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        : <span onDoubleClick={onEdit} className="unselectable">
            { props.content }
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </span>
      }
    </h2>
    <h4>
      {
        editingTophat
        ? <Input
            fullWidth
            defaultValue={props.tophat}
            ref={inputTophatRef}
            autoFocus
            onKeyDown={onTophatKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onTophatEdited}>
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={_=>setEditingTophat(false)}>
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        : <span onDoubleClick={onTophatEdit} className="unselectable">
            { props.tophat || "cartola" }
            <IconButton onClick={onTophatEdit}>
              <EditIcon />
            </IconButton>
          </span>
      }
    </h4>
  </>;
}

export default Title;
