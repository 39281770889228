import React from 'react';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SaveIcon from '@material-ui/icons/CloudUploadOutlined';
import NothingToSaveIcon from '@material-ui/icons/CloudDoneOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.scss';

function Publish (props) {
  const [saving, setSaving] = React.useState(false);
  
  React.useEffect(() => {
  }, []);

  function publishArticle () {
    if (!props.hasChange) {
      return;
    }
    
    setSaving(true);
    props.publishArticle()
      .then(_ => {
        setSaving(false);
      })
      .catch(error => {
        setSaving(false);
        alert(error.message || 'Erro ao tentar fazer a publicação!');
        console.error(error);
      });
  }

  const theIcon = props.hasChange && !saving
    ? <Badge color="secondary" variant="dot">
        <SaveIcon />
      </Badge>
    : <NothingToSaveIcon />;

  return (
    <ListItem
      button
      key={'Publicar'}
      className={saving ? 'publishing-menu' : ''}
      onClick={publishArticle}
    >
      <ListItemIcon>
        {
          saving
            ? <span className='loading-icon'><CircularProgress size={20} /></span>
            : theIcon
        }
      </ListItemIcon>
      <ListItemText primary={saving ? 'Publicando...' : props.hasChange ? 'Publicar' : 'Publicado'} />
    </ListItem>
  );
}

export default Publish;
