class Logger {
  constructor (name) {
    this.groupName = name;
    this.styles = {
      log: 'background-color: #f0f0f0; font-weight: bold; color: #000;',
      info: 'background-color: #3060ff; font-weight: bold; color: #fff;',
      warn: 'background-color: #ff0; font-weight: bold; color: #000;',
      error: 'background-color: #f33; color: #fff;'
    };
  }

  group (...args) {
    console.group(`%c ${this.groupName} `, this.styles.log, ...args);
  }

  groupEnd () {
    console.groupEnd(`%c ${this.groupName} `);
  }

  groupCollapsed (...args) {
    console.groupCollapsed(`%c ${this.groupName} `, this.styles.log, ...args);
  }

  log (...args) {
    console.log(`%c ${this.groupName} `, this.styles.log, ...args);
  }
  
  info (...args) {
    console.info(`%c ${this.groupName} `, this.styles.info, ...args);
  }
  
  warn (...args) {
    console.warn(`%c ${this.groupName} `, this.styles.warn, ...args);
  }
  
  error (...args) {
    console.error(`%c ${this.groupName} `, this.styles.error, ...args);
  }
}

export default Logger;
