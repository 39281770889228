import React from 'react';
import Block from '../index.js';
import Modal from '../../modal';
import TextField from '@material-ui/core/TextField';
import './index.scss';

const playerOptions = 'modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&theme=light&fs=0&autohide=0';
// const videoThumb = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

function Video (props) {
  const [ editing, setEditing ] = React.useState(false);
  const parsedURL = getEmbedURL(props.data.url);
  const [ embedURLTmp, setEmbedURLTmp ] = React.useState(parsedURL || '');
  const [ embedURL, setEmbedURL ] = React.useState(parsedURL);

  function onInfo () {
    return <span>Este vídeo será embedado no post.</span>;
  }

  function onEdit () {
    setEditing(true);
  }

  function onEdited (event) {
    const url = getEmbedURL(embedURLTmp);
    props.onEdit({ url });
    setEmbedURL(url);
    setEditing(false);
  }

  function getEmbedURL (embedURL) {
    if (embedURL) {
      const url = new URL(embedURL);
      const videoId = url.searchParams.get('v');
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}?${playerOptions}`;
      }
      return embedURL;
    } else {
      return null;
    }
  }

  function handleUrlChange (event) {
    setEmbedURLTmp(event.target.value);
  }

  return <>
    <Block
      {...props}
      onInfo={onInfo}
      onEdit={onEdit}
    >
      <div className='block-video-container'>
        {
          embedURL
          ? <iframe
              title="Youtube"
              width="560"
              height="315"
              src={embedURL}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              // allowFullscreen
            />
          : <div className="no-video"></div>
        }
      </div>
    </Block>
    <Modal
      title='Editando...'
      maxWidth='md'
      onSave={onEdited}
      open={!!editing}
      saveLabel="Aplicar"
      onClose={_=>{setEditing(false)}}
      body={<div>
        <TextField
          // id="outlined-name"
          label="URL do vídeo"
          fullWidth
          // name="url"
          onChange={handleUrlChange}
          // disabled={loading}
          inputProps={{
            name: 'url',
            type: 'url',
            placeholder: 'https://youtube.com/watch/?v=________',
            autoComplete: 'off'
          }}
          // className={classes.textField}
          value={embedURLTmp}
          margin="normal"
          variant="outlined"
        />
      </div>}
    />
  </>;
}

export default Video;
