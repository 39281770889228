import React from 'react';
import IconTune from '@material-ui/icons/Tune';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '../modal/';
import LanguagePicker from '../language-list/';
import { storeOptions } from '../../resources/default-settings';
import './index.scss';

function Settings (props) {
  const title = 'Configurações';
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const [languages, setLanguages] = React.useState(null);
  
  const handleChange = event => {
    const target = event.target;

    props.settings.set(target.name, target.value);
  };

  function onSave () {
    setLoading(true);
    storeOptions(props.settings);
    setTimeout(_ => {
      setLoading(false);
      setOpen(false);
    }, 500);
  }

  React.useEffect(() => {});

  const values = props.settings;

  return (
    <>
      <ListItem
        button
        key={title}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><IconTune /></ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      <Modal
        title={title}
        overflow="visible"
        body={<div>
          <table>
            <tbody>
              {/* <tr>
                <td>Dividir páginas automaticamente<br/>a cada</td>
                <td>
                  <FormControl
                    variant="outlined"
                    // className={classes.formControl}
                    style={{width: '140px'}}
                  >
                    <InputLabel
                      // ref={inputLabel}
                      htmlFor="outlined-age-simple">
                      parágrafos
                    </InputLabel>
                    <Select
                      value={values.dividePages}
                      name="dividePages"
                      onChange={handleChange}
                      labelWidth={100}
                      inputProps={{
                        name: 'dividePages'
                      }}
                    >
                      {
                        [0, 1, 2, 3, 4, 5, 6].map(i => <MenuItem key={i} value={i}>{
                          i === 0 ? "Não dividir" : i
                        }</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </td>
              </tr> */}
              {/* <tr>
                <td>Padrão para mídias em artigos</td>
                <td>
                  <FormControl
                    variant="outlined"
                    style={{width: '140px'}}
                  >
                    <Select
                      value={values.mediaGaleryType}
                      name="mediaGaleryType"
                      onChange={handleChange}
                      inputProps={{
                        name: 'mediaGaleryType'
                      }}
                    >
                      <MenuItem key={'galery'} value={'galery'}>Galeria</MenuItem>
                      <MenuItem key={'carousel'} value={'carousel'}>Carrossel</MenuItem>
                      <MenuItem key={'list'} value={'list'}>Lista</MenuItem>
                      <MenuItem key={'full'} value={'full'}>Tela cheia</MenuItem>
                      <MenuItem key={'none'} value={'none'}>Sem mídia</MenuItem>
                    </Select>
                  </FormControl>
                </td>
              </tr> */}
              <tr>
                <td style={{
                  verticalAlign: 'top',
                  paddingTop: '25px'
                }}>Idioma padrão</td>
                <td>
                  <LanguagePicker
                    value={values.defaultLanguage}
                    onChange={value => {
                      handleChange({
                        target: {
                          name: 'defaultLanguage',
                          value
                        }
                      })
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{
                  verticalAlign: 'top',
                  paddingTop: '25px'
                }}>Idiomas disponíveis</td>
                <td>
                  <LanguagePicker
                    multiple
                    value={values.languages}
                    onChange={value => {
                      handleChange({
                        target: {
                          name: 'languages',
                          value
                        }
                      })
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>}
        maxWidth='sm'
        open={open}
        onSave={onSave}
        loading={loading}
        onClose={_=>{setOpen(false)}}
      />
    </>
  );
}

export default Settings;
