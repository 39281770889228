import React, { useEffect } from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import uniqid from 'uuid/v4';
import { getArticleInLanguage, findArticleImage, getArticleAddresses } from '../../resources/utils';
import Modal from '../modal';
import { mutations } from '../../graphql';
import EditableInfo from '../editable-info';
import UploadButton from '../upload-button';

import './index.scss';

function getArticleDetails (article, settings, curLang, currentPublisher) {
  if (!article) {
    return null;
  }

  const data = {};
  // data.link = `${settings.readerServerBaseURL}${article.publisherId || currentPublisher.id}/${article.id}?page=1`;
  data.links = getArticleAddresses(currentPublisher, article);
  data.source = article.source;
  
  data.type = article.type || 'ARTICLE';
  data.sponsor = article.sponsor || {};

  const contentInLang = getArticleInLanguage(article, curLang || settings.defaultLanguage.value);
  data.image = findArticleImage(article, curLang || settings.defaultLanguage.value)
  data.title = contentInLang.title || '';
  data.categories = article.tags || [];
  data.pages = {};
  article.contents.forEach(content => {
    if (content) {
      const l = content.language;
      data.pages[l] = data.pages[l] || 0;
      data.pages[l]+= content.pages.length;
    }
  });

  data.tags = {
    edits: [],
    cats: [],
    tags: []
  };

  (data.categories || []).forEach(tag => {
    if (tag.kind === 'EDITORSHIP') {
      data.tags.edits.push(tag);
      return;
    }
    if (tag.kind === 'TAG') {
      data.tags.tags.push(tag);
      return;
    }
    data.tags.cats.push(tag);
  });

  return data;
}

function ArticleInfo (props) {
  const modalTitle = 'Informações';
  const [open, setOpen] = React.useState(false);
  const [editingMainImage, setEditingMainImage] = React.useState(false);
  const [authorLoading, setAuthorLoading] = React.useState(false);
  
  useEffect(() => {
    if(editingMainImage !== false) {
      updateArticleMainImage();
      return;
    }
  }, [editingMainImage]);

  function downloadCSV (data, lang, /* article */) {
    const rows = [
      ['Item id', 'Thumbnail', 'Title', 'Description', 'URL', 'Status', 'Uploaded On'].join(',')
    ];

    const content = props.article.contents.find(c => c.language === lang) || {pages: []};
    content.pages.forEach(page => {
      const shortDesc = (page.blocks.find(b => b.kind === 'paragraph') || {data: {content: ''}}).data.content;
      const el = document.createElement('span');
      el.innerHTML = shortDesc;
      const cols = [
        '', // uniqid(), // Item id
        content.image ? content.image : null, // Thumbnail
        JSON.stringify(`${data.title}`), // Title
        JSON.stringify(`${el.innerText.substring(0, 140)}`), // Description
        // data.link, // URL
        'Need to Edit', // 'Active', // status
        new Intl.DateTimeFormat('pt-BR').format(new Date()) // uploaded on
      ];
      rows.push(cols.join(','));
    });
    
    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "campaign.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  }

  function removeLanguage (lang) {
    const langIdx = article.contents.findIndex(c => c.language === lang);
    if (langIdx >= 0 && window.confirm('Confirma a exclusão deste idioma para este artigo?')) {
      article.contents = article.contents.filter((c, i) => i === langIdx ? null: c);
      props.setCurrentArticle(props.article);
    }
  }

  const {
    settings,
    article
  } = props;

  const data = getArticleDetails(article, settings, props.currentLang.value, props.currentPublisher);

  function updateArticleMainImage () {
    const contentInLang = article.contents.find(c => c.language === props.currentLang.value);
    contentInLang.image = editingMainImage;
    props.setCurrentArticle(article);
    setEditingMainImage(false);
  }

  async function updateArticleAuthor (val) {
    setAuthorLoading(true);
    setTimeout(setAuthorLoading, 500);
    article.author = article.author || { id: uniqid() };
    article.author.firstName = val;
    article.author.lastName = null;
    await mutations.upsertAuthor(article.author);
    props.setCurrentArticle(article);
    setEditingMainImage(false);
  }

  return (
    <>
      <ListItem
        button
        key={modalTitle}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><InfoIcon /></ListItemIcon>
        <ListItemText primary={modalTitle} />
      </ListItem>
      <Modal
        title={modalTitle}
        maxWidth='sm'
        open={open}
        onClose={_=>{setOpen(false)}}
        disableEnforceFocus={true}
        body={<div>
          {
            data
            ? <>
                <div className="info-container">
                  <div className="info-title">Título:</div>
                  <div className="info-value">{data.title}</div>
                </div>
                <div className="info-container">
                  <div className="info-title">Imagem em<br/>destaque:</div>
                  <div className="info-value">
                    {
                      (!data.image || !data.image) || editingMainImage !== false ?
                        <div className="main-image-field-container">
                            <UploadButton
                              text="Adicionar"
                              folder={props.currentPublisher.id}
                              setURL={setEditingMainImage}
                            />
                        </div>
                    :
                      <div className="image-picker">
                        <div
                          className='main-image-preview'
                          style={{backgroundImage: `url(${data.image})`}}>
                        </div>
                        <UploadButton
                          folder={props.currentPublisher.id}
                          setURL={setEditingMainImage}
                        />
                      </div>
                    }
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Tipo:</div>
                  <div className="info-value">
                    <select
                      value={data.type || 'ARTICLE'}
                      onChange={event => {
                        article.type = event.target.value;
                        props.setCurrentArticle(props.article);
                      }}
                    >
                      <option value="ARTICLE">Artigo</option>
                      <option value="TICKER">Ticker</option>
                    </select>

                    <div className="article-type-info">
                      {
                        data.type === 'TICKER'
                          ? 'Conteúdo único, publicado em área de destaque no topo do side'
                          : 'Artigo tradicional'
                      }
                    </div>
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Links:</div>
                  <div className="info-value">
                    {
                      data.links
                      ? Object.keys(data.links || {}).map(lang => {
                          return <div key={lang}>
                            <strong>{lang}</strong><br/>
                            <span>
                              {
                                data.links &&
                                data.links[lang] &&
                                data.links[lang].map(url => {
                                  return <a
                                    href={url}
                                    key={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                      {url}<br/>
                                  </a>;
                                })
                              }
                            </span>
                          </div>;
                        })
                      : <sub className="danger">O artigo precisa de ao menos uma vertical(editoria) para ser publicado</sub>
                    }
                    <a href={data.link} target="about:blank">{data.link}</a>
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Autor:</div>
                  <div className="info-value">
                    <div className="author-field-container">
                      <EditableInfo
                        value={article.author? article.author.firstName : ''}
                        onChange={updateArticleAuthor}
                        loading={!!authorLoading}
                        placeHolder="Sem autor(a) definido(a)"
                      />
                    </div>
                  </div>
                </div>
                {
                  data.source
                  ? <div className="info-container">
                      <div className="info-title">Fonte:</div>
                      <div className="info-value"><a href={data.source} target="about:blank">{data.source}</a></div>
                    </div>
                  : null
                }
                <div className="info-container">
                  <div className="info-title">Verticais:</div>
                  <div className="info-value">
                    {
                      data.tags.edits.map(cat => (
                        <span className="info-category" key={cat.name}>{cat.name}</span>
                      ))
                    }
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Categorias:</div>
                  <div className="info-value">
                    {
                      data.tags.cats.map(cat => (
                        <span className="info-category" key={cat.name}>{cat.name}</span>
                      ))
                    }
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Tags:</div>
                  <div className="info-value">
                    {
                      data.tags.tags.map(cat => (
                        <span className="info-category" key={cat.name}>{cat.name}</span>
                      ))
                    }
                  </div>
                </div>
                <table className="info-table">
                  <thead>
                    <tr>
                      <td>Idioma</td>
                      <td>Número de páginas</td>
                      <td>Exportar</td>
                      <td>Excluir</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(data.pages || {}).map(lang => {
                        return <tr key={lang}>
                          <td className="info-lang">{lang}</td>
                          <td className="info-pages">{data.pages[lang]}</td>
                          <td className="info-csv" onClick={_ => downloadCSV(data, lang, article)}>CSV</td>
                          <td
                            className="info-del"
                            onClick={_ => removeLanguage(lang)}
                          >
                            <DeleteIcon />
                          </td>
                        </tr>;
                      })
                    }
                  </tbody>
                </table>
              </>
            : <span>É preciso carregar um artico para ver estas informações</span>
          }
        </div>}
      />
    </>
  );
}

export default ArticleInfo;
