import React from 'react';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
// import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import ImagePicker from '../image-picker';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import { queries } from '../../graphql';

import cellphoneWireframe from './wireframe-device-browser-cellphone.png';
import windowWireframe from './wireframe-device-browser-window.jpg';

// import { Logger } from '../../resources/utils';

import './index.scss';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

export default function PublisherEditor (props) {

  const { publisher, onChange } = props;
  const [ formData, setFormDataState ] = React.useState(props.publisher);
  const [ customizations, setCustomizationsState ] = React.useState({});
  const [ value, setValue ] = React.useState(0);
  const [ headerSize, setHeaderSize ] = React.useState(3);
  const [ headerPosition, setHeaderPosition ] = React.useState(0);
  const [ availableSocialMedia, setAvailableSocialMedia ] = React.useState([]);
  
  const [anchorHeaderSize, setAnchorHeaderSize] = React.useState(null);
  const [anchorHeaderPosition, setAnchorHeaderPosition] = React.useState(null);

  const { customizationsList } = publisher;

  React.useEffect(_ => {
    if (customizationsList && customizationsList[0]) {
      const data = JSON.parse(customizationsList[0].value);

      let imgCustomizations = null;

      if (data.headerBGColor) {
        setHeaderBgColor(data.headerBGColor);
      }

      if (data.headerSizeOption !== undefined) {
        setHeaderSize(data.headerSizeOption);
        const headerSize = headerSizeOptions[data.headerSizeOption];
        imgCustomizations = Object.assign({}, imgCustomizations || headerBgImage, headerSize.value);
      }

      if (data.headerPositionOption !== undefined) {
        setHeaderPosition(data.headerPositionOption);
        imgCustomizations = Object.assign({}, imgCustomizations || headerBgImage, headerSize.value);
      }

      if (imgCustomizations) {
        setHeaderBgImage(imgCustomizations);
      }

      setCustomizationsState(data);
    }
  }, [customizationsList]);

  // React.useEffect(_ => {
  //   const obj = {};
  //   obj.logo = 
  //   setFormDataState(obj);
  // }, [publisher]);
  
  const headerSizeOptions = [
    { label: "cobre o",                     value: { backgroundSize: 'cover',     backgroundRepeat: 'no-repeat' } },
    { label: "estica-se pelo",               value: { backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' } },
    { label: "não estica nem se repete pelo",  value: { backgroundSize: 'contain',   backgroundRepeat: 'no-repeat' } },
    { label: "repete-se pelo",               value: { backgroundSize: 'auto',      backgroundRepeat: 'repeat' } },
    { label: "repete-se verticalmente pelo",   value: { backgroundSize: '100% auto', backgroundRepeat: 'repeat-y' } },
    { label: "repete-se horizontalmente pelo", value: { backgroundSize: 'auto 100%', backgroundRepeat: 'repeat-x' } }
  ];
  
  const headerPositionOptions = [
    { label: "no topo, à esquerda",  value: { backgroundPosition: 'top left' } },
    { label: "no topo",            value: { backgroundPosition: 'top center' } },
    { label: "no topo, à direita",   value: { backgroundPosition: 'top right' } },
    { label: "à esquerda",          value: { backgroundPosition: 'center, left' } },
    { label: "no centro",           value: { backgroundPosition: 'center center' } },
    { label: "à direita",           value: { backgroundPosition: 'center right' } },
    { label: "em baixo, à esquerda",  value: { backgroundPosition: 'bottom left' } },
    { label: "em baixo",            value: { backgroundPosition: 'bottom center' } },
    { label: "em baixo, à direita",  value: { backgroundPosition: 'bottom right' } },
  ];

  const [ headerBgColor, setHeaderBgColor ] = React.useState('rgba(0, 0, 0, .3)');
  const [ headerBgImage, setHeaderBgImage ] = React.useState({});
  
  function setFormData (obj) {
    setFormDataState(obj);
    if (onChange) {
      onChange(obj);
    }
  }

  function setCustomizations (obj) {
    setCustomizationsState(obj);
    if (props.onCustomizationChange) {
      props.onCustomizationChange(obj);
    }
  }

  const handleHeaderSizeClick = (event) => {
    setAnchorHeaderSize(event.currentTarget);
  };

  const handleHeaderPositionClick = (event) => {
    setAnchorHeaderPosition(event.currentTarget);
  };

  const handleHeaderSizeMenus = (event) => {
    if (event.target.nodeName === 'LI') {
      const value = headerSizeOptions[event.target.value].value;
      const tmp = Object.assign({}, headerBgImage, value);
      setHeaderBgImage(tmp);
      setHeaderSize(event.target.value);

      const cust = {...customizations};
      cust['headerSize'] = value;
      cust['headerSizeOption'] = event.target.value;
      setCustomizations(cust);
      // const fd = {...formData};
      // fd['headerSize'] = value;
      // fd['headerSizeOption'] = event.target.value;
      // setFormData(fd);
    }
    
    setAnchorHeaderSize(null);
  };

  const handleHeaderPositionMenus = (event) => {
    if (event.target.nodeName === 'LI') {
      const value = headerPositionOptions[event.target.value].value;
      const tmp = Object.assign({}, headerBgImage, value);
      setHeaderBgImage(tmp);
      setHeaderPosition(event.target.value);

      const cust = {...customizations};
      cust['headerPosition'] = value;
      cust['headerPositionOption'] = event.target.value;
      setCustomizations(cust);
      // setFormDataState(obj);
      // setFormData(fd);
    }

    setAnchorHeaderPosition(null);
  };

  // const {
  //   socialMediaPublishersList = []
  // } = (publisher || {});

  // get all the currently available social networks
  React.useEffect(() => {
    queries.getSocialMedias()
      .then(ret => {
        setAvailableSocialMedia(ret.data.socialMediasList);
      }).catch(error => {
        // debugger;
        console.error(error);
      })
  }, []);

  if (!publisher) {
    return <div>Nenhum publisher especificado.</div>
  }

  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  function updateFormData(event) {
    const fd = {...formData};
    fd[event.target.name] = event.target.value;
    setFormData(fd);
  }

  function updateCustomization(event) {
    const cust = {...customizations};
    cust[event.target.name] = event.target.value;
    setCustomizations(cust);
  }

  console.log(1111, customizations);

  return (
    <div className="publisher-edits-container">
      {/* <AppBar position="static">
        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Dados" {...a11yProps(0)} />
          <Tab label="Redes Sociais" {...a11yProps(1)} />
          <Tab label="Aparência" {...a11yProps(2)} />
        </Tabs>
      </AppBar> */}
      {/* <TabPanel value={value} index={0}> */}
        <TextField
          id="publisher-name"
          name="companyFullname"
          label="Nome"
          variant="outlined"
          maxLength="60"
          fullWidth
          onChange={updateFormData}
          // inputRef={editPublisherName}
          autoFocus
          defaultValue={publisher.companyName}
        />
        <br /><br />
        <TextField
          id="publisher-doc"
          name="cnpj"
          label="CNPJ"
          variant="outlined"
          onChange={updateFormData}
          fullWidth
          // inputRef={editPublisherDoc}
          defaultValue={publisher.cnpj}
        /><br/><br/>
        <TextField
          id="publisher-url"
          name="url"
          label="URL"
          variant="outlined"
          inputProps={{type: 'url'}}
          onChange={updateFormData}
          fullWidth
          // inputRef={editPublisherURL}
          defaultValue={publisher.url}
        /><br /><br />
        <div style={{display: 'none'}}>
          <label htmlFor="publisher-status">Status</label>
          <Switch
            defaultChecked={publisher.active}
            id="publisher-status"
            name="active"
            onChange={updateFormData}
            value="checkedB"
            color="primary"
            // inputRef={editPublisherStatus}
            inputProps={{ 'aria-label': 'status' }}
          />
        </div>
        {/* <br/><br/> */}
        <TextField
          id="publisher-analytics-id"
          name="publisherAnalyticsCode"
          label="Código Analytics"
          variant="outlined"
          placeholder={"UA-XXXXXXX"}
          // inputProps={{type: 'url'}}
          onChange={updateCustomization}
          // fullWidth
          // inputRef={editPublisherURL}
          value={console.log(customizations) || customizations.publisherAnalyticsCode}
        />
      {/* </TabPanel> */}
      {/* <TabPanel value={value} index={1}>
        <div className="social-media-list-container">
          {
            availableSocialMedia.map(socialNetwork => {
              return <div className="social-media-item-container" key={socialNetwork.id}>
                <img src={socialNetwork.icon} alt={socialNetwork.name} />
                <TextField
                  // label="Logo usado nas redes sociais"
                  placeholder={"https://" + socialNetwork.name.toLowerCase() + '...'}
                  variant="outlined"
                  inputProps={{type: 'url'}}
                  fullWidth
                  name={"socialNetwork-" + socialNetwork.id}
                  onChange={updateFormData}
                  // inputRef={editPublisherLogo}
                  defaultValue={'TODO'} // publisher.logo}
                />
              </div>;
            })
          }
        </div>
        <br /><br />
      </TabPanel> */}
      {/* <TabPanel value={value} index={2}> */}

        <div className="appearence-container">
          <div className="ac-inputs">
            {/* <TextField
              label="Logo no site"
              variant="outlined"
              inputProps={{type: 'url'}}
              className="input"
              name="logo"
              onChange={updateFormData}
              // fullWidth
              // inputRef={editPublisherLogo}
              value={formData.logo}
            /> */}

            <h2>Imagens</h2>

            <ImagePicker
              title={"Logo no site"}
              settings={props.settings}
              currentPublisher={publisher}
              onClear={event => {
                updateFormData({
                  target: {
                    name: 'logo',
                    value: ''
                  }
                });
              }}
              onSelected={selectedList => {
                updateFormData({
                  target: {
                    name: 'logo',
                    value: selectedList[0].secure_url
                  }
                });
              }}
              buttonElement={
                <Button variant="contained">
                  {
                    formData.logo
                    ? <img
                        src={formData.logo}
                        alt="Logo"
                        width="100"
                      />
                    : null
                  }
                  <span>Logo no site</span>
                </Button>
              }
            />

            <br/>
            
            <ImagePicker
              title={"Ícone"}
              settings={props.settings}
              currentPublisher={publisher}
              onClear={event => {
                updateCustomization({
                  target: {
                    name: 'icon',
                    value: ''
                  }
                });
              }}
              onSelected={selectedList => {
                updateCustomization({
                  target: {
                    name: 'icon',
                    value: selectedList[0].secure_url
                  }
                });
              }}
              buttonElement={
                <Button variant="contained">
                  {
                    customizations.icon
                    ? <img
                        src={customizations.icon}
                        alt="ícone"
                        width="50"
                      />
                    : null
                  }
                  <span>Ícone</span>
                </Button>
              }
            />

            {/* <TextField
              id="publisher-sn-logo"
              label="Ícone"
              variant="outlined"
              inputProps={{type: 'url'}}
              className="input"
              fullWidth
              name="icon"
              onChange={updateCustomization}
              // inputRef={editPublisherLogo}
              value={customizations.icon} // publisher.logo}
            /> */}

            <fieldset className="header-options-container">
              <legend>Header</legend>

              Cor de fundo:&nbsp;
              <input
                type="color"
                value={headerBgColor}
                name="headerBGColor"
                onChange={updateCustomization}
                onInput={event =>setHeaderBgColor(event.target.value)}
              /><br/><br/>

              {/* <TextField
                label="Imagem de fundo"
                variant="outlined"
                inputProps={{type: 'url'}}
                className="input"
                fullWidth
                name="headerImage"
                onChange={updateCustomization}
                // inputRef={editPublisherLogo}
                value={customizations.headerImage} // publisher.logo}
              /> */}
              <ImagePicker
                title={"Imagem de fundo"}
                settings={props.settings}
                currentPublisher={publisher}
                onClear={event => {
                  updateCustomization({
                    target: {
                      name: 'headerImage',
                      value: ''
                    }
                  });
                }}
                onSelected={selectedList => {
                  updateCustomization({
                    target: {
                      name: 'headerImage',
                      value: selectedList[0].secure_url
                    }
                  });
                }}
                buttonElement={
                  <Button variant="contained">
                    {
                      customizations.headerImage
                      ? <img
                          src={customizations.headerImage}
                          alt="Imagem de fundo"
                          width="50"
                        />
                      : null
                    }
                    <span>Imagem de fundo</span>
                  </Button>
                }
              />

              <br/>

              A imagem 
              <span
                className="link-menu-trigger"
                onClick={handleHeaderSizeClick}
              >
                {headerSizeOptions[headerSize].label}
                <ArrowDropDownIcon />
              </span>
              <Menu
                anchorEl={anchorHeaderSize}
                keepMounted
                open={Boolean(anchorHeaderSize)}
                onClose={handleHeaderSizeMenus}
              >
                {
                  headerSizeOptions.map((hso, i) => {
                    return <MenuItem key={hso.label} onClick={handleHeaderSizeMenus} value={i}>{hso.label}</MenuItem>;
                  })
                }
              </Menu>
              header.<br/><br/>

              A área mais relevante fica 
              <span className="link-menu-trigger" onClick={handleHeaderPositionClick}>
                {headerPositionOptions[headerPosition].label}
                <ArrowDropDownIcon />
              </span>
              <Menu
                anchorEl={anchorHeaderPosition}
                keepMounted
                open={Boolean(anchorHeaderPosition)}
                onClose={handleHeaderPositionMenus}
              >
                {
                  headerPositionOptions.map((hso, i) => {
                    return <MenuItem key={hso.label} onClick={handleHeaderPositionMenus} value={i}>{hso.label}</MenuItem>;
                  })
                }
              </Menu>
              do header.
            </fieldset>
          </div>
          <div className="ac-preview">
            <span className="preview-title">Pré-visualização</span>
            <div className="preview-header-container">
              <div className="desktop-container" style={{backgroundImage: `url(${windowWireframe})`}}>
                <div className="browser-fav-sim" style={{backgroundImage: `url(${customizations.icon})`}}></div>
                <div
                  className="header-sim"
                  style={{
                    backgroundColor: headerBgColor,
                    backgroundImage: `url(${customizations.headerImage})`,
                    ...headerBgImage
                    // backgroundRepeat: headerBgImage.repeat,
                    // backgroundPosition: headerBgImage.position,
                    // backgroundSize: headerBgImage.size
                  }}
                >
                  {
                    formData.logo
                    ? <img src={formData.logo} />
                    : null
                  }
                </div>
              </div>
              <div className="mobile-container" style={{backgroundImage: `url(${cellphoneWireframe})`}}>
                <div
                  className="header-sim"
                  style={{
                    backgroundColor: headerBgColor,
                    backgroundImage: `url(${customizations.headerImage})`,
                    ...headerBgImage
                  }}
                >
                  {
                    formData.logo
                    ? <img src={formData.logo} />
                    : null
                  }
                </div>
              </div>
              <div className="preview-sn-container">
                <div className="dark" style={{backgroundImage: `url(${customizations.icon})`}}></div>
                <div className="light" style={{backgroundImage: `url(${customizations.icon})`}}></div>
              </div>
            </div>
            {/* /wireframe-device-browser-window.jpg */}
          </div>
        </div>
      {/* </TabPanel> */}
    </div>
  );
}
