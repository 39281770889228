import React from 'react';
import { Collapse } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import OrderDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OrderUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Modal from '../modal';

import AddEmbedIcon from '@material-ui/icons/Code';
import AddParagraphIcon from '@material-ui/icons/Menu';
import RemoveBlockIcon from '@material-ui/icons/NotInterestedOutlined';
import DuplicateBlockIcon from '@material-ui/icons/FileCopyOutlined';
import DividePagesIcon from '@material-ui/icons/VerticalAlignCenter';
// import AddAdIcon from '@material-ui/icons/AttachMoney';
import EditIcon from '@material-ui/icons/Edit';
import AddImageIcon from '@material-ui/icons/PermMedia';
import AddVideoIcon from '@material-ui/icons/SlideshowOutlined';
// import AddRelatedIcon from '@material-ui/icons/CalendarViewDayOutlined';
import AddBlockIcon from '@material-ui/icons/Add';
// import AddNavigationButtonsIcon from '@material-ui/icons/CompareArrows';

import './index.scss';

function Block (props) {

  const [ hoveredTool, setHoveredTool ] = React.useState('');
  const [ modalPrompt, setModalPrompt ] = React.useState(false);
  const [ subToolsVisible, setSubToolsVisible ] = React.useState(false);

  function toolBtnOut (event) {
    setHoveredTool('');
  }

  function toolBtnHover (event) {
    const t = event.target;
    const kind = t.dataset.toolKind;
    setHoveredTool(kind);
  }

  function toggleSubtools () {
    setSubToolsVisible(!subToolsVisible);
  }

  function showBlockInfo () {
    setModalPrompt({
      title: 'Informações',
      body: props.onInfo()
    });
  }

  function removeBlock () {
    if (window.confirm('Confirma a exclusão deste bloco?')) {
      props.onRemove();
    }
  }

  function contentDblClick () {
    if (props.editOnDblClick) {
      props.onEdit();
    }
  }
  
  return <div
    className="block-item-container"
    data-hovered-tool={hoveredTool}
    data-sub-tools-open={subToolsVisible}
    data-block-id={props.blockId}
    data-block-sort={props.sort}
  >
    {
      !props.disableHeader
      ? <div className="header-tools" data-sort={props.sort}>
          {/* {props.page}-{props.sort} */}
          <OrderDownIcon title="Empurrar para abaixo" onClick={props.onMoveDown} />
          <OrderUpIcon title="Empurrar para cima" onClick={props.onMoveUp} />
          {
            props.onInfo
            ? <InfoIcon title="Mais informações" onClick={showBlockInfo} />
            : null
          }
        </div>
      : null
    }
    
    {
      props.children
      ? <div
          className="block-content"
          onDoubleClick={contentDblClick}
        >
          {props.children}
        </div>
      : null
    }

    <div className="block-tools">
      {
        !props.disableEdit
        ? <Zoom in={!subToolsVisible}>
            <div
              className="block-tool edit"
              data-tool-kind="edit"
              onMouseOver={toolBtnHover}
              onMouseOut={toolBtnOut}
              onClick={props.onEdit}
              title="Editar este bloco"
            >
              <EditIcon className="block-tool-icon" />
            </div>
          </Zoom>
        : null
      }
        <div
          className="block-tool add"
          data-tool-kind="add"
          onMouseOver={toolBtnHover}
          onMouseOut={toolBtnOut}
          onClick={toggleSubtools}
          title="Adicionar novo bloco abaixo"
        >
          <AddBlockIcon className="block-tool-icon" />
        </div>

      {
        !props.disableRemove
        ? <Zoom in={!subToolsVisible}>
            <div
              className="block-tool remove"
              data-tool-kind="remove"
              onMouseOver={toolBtnHover}
              onMouseOut={toolBtnOut}
              onClick={removeBlock}
              title="Excluir este bloco"
            >
              <RemoveBlockIcon className="block-tool-icon" />
            </div>
          </Zoom>
        : null
      }
    </div>
    <Collapse in={subToolsVisible} >
      <div className="block-sub-tools">
        {/* <div
          className="block-tool"
          title="Novo espaço para publicidade"
          onClick={_ => {
            props.addAd();
            setSubToolsVisible(false);
          }}
        >
          <AddAdIcon />
        </div> */}
        <div
          className="block-tool"
          title="Novo parágrafo"
          onClick={_ => {
            props.addBlock();
            setSubToolsVisible(false);
          }}
        >
          <AddParagraphIcon />
        </div>

        {/* <div
          className="block-tool"
          title="Artigos relacionados"
          onClick={_ => {
            props.addRelated();
            setSubToolsVisible(false);
          }}
        >
          <AddRelatedIcon />
        </div> */}
        <div
          className="block-tool"
          title="Nova imagem"
          onClick={_ => {
            props.addImage();
            setSubToolsVisible(false);
          }}
        >
          <AddImageIcon />
        </div>
        <div
          className="block-tool"
          title="Adicionar vídeo"
          onClick={_ => {
            props.addVideo();
            setSubToolsVisible(false);
          }}
        >
          <AddVideoIcon />
        </div>
        <div
          className="block-tool"
          title="Embedar conteúdo"
          onClick={_ => {
            props.addEmbed();
            setSubToolsVisible(false);
          }}
        >
          <AddEmbedIcon />
        </div>
        <div
          className="block-tool"
          title="Nova quebra de página"
          onClick={_ => {
            props.addPageBreak();
            setSubToolsVisible(false);
          }}
        >
          <DividePagesIcon />
        </div>
        {/* <div
          className="block-tool"
          title="Botões de navegação (página anterior & próxima página"
          onClick={_ => {
            props.addNavButtons();
            setSubToolsVisible(false);
          }}
        >
          <AddNavigationButtonsIcon />
        </div> */}
        <div
          className="block-tool"
          title="Duplicar/Clonar bloco" onClick={_=>{
            props.duplicate();
            setSubToolsVisible(false);
          }}
        >
          <DuplicateBlockIcon />
        </div>
      </div>
    </Collapse>

    {
      modalPrompt
      ? <Modal
          title={modalPrompt.title}
          body={modalPrompt.body}
          maxWidth='sm'
          open={true}
          onClose={_=>{setModalPrompt(false)}}
        />
      : null
    }
  </div>;
}

export default Block;
