import React from 'react';
import Block from '../index.js';
import './index.scss';


function NavButtons (props) {
  function onInfo () {
    return <span>Campos de navegação, para página anterior ou próxima</span>
  }

  return <Block
    {...props}
    onInfo={onInfo}
  >
    <div className='block-nav-container'>
      <div className="btn-left"></div>
      <div className="btn-right"></div>
    </div>
  </Block>;
}

export default NavButtons;
