import React from 'react';
import IconSubdirectoryArrowLeft from '@material-ui/icons/SubdirectoryArrowLeft';
import Arbitragem from './arbitragem-editor/';
import { getArticleInLanguage } from '../../resources/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '../modal';
import IconButton from '@material-ui/core/IconButton';
import PublisherEditor from '../publisher-edit';
import './index.scss';

function MainEditor (props) {
  const [editingPublisher, setEditingPublisher] = React.useState(false);

  const [publisherCustomizations, setPublisherCustomizations] = React.useState([]);
  const [publisherEditingData, setPublisherEditingData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  // const editPublisherName = React.useRef('editPublisherName');
  // const editPublisherDoc = React.useRef('editPublisherDoc');
  // const editPublisherLogo = React.useRef('editPublisherLogo');
  // const editPublisherURL = React.useRef('editPublisherURL');
  // const editPublisherStatus = React.useRef('editPublisherStatus');

  React.useEffect(() => {
    if (props.current) {
      props.setAppTitle(`Editando Artigo ${props.currentLang ? `(${props.currentLang.value.toUpperCase()})` : ''}`);
    } else {  
      props.setAppTitle('Para você começar, você pode:');
    }
  });

  React.useEffect(_ => {
    if (props.currentPublisher && props.currentPublisher.customizationsList) {
      setPublisherCustomizations(props.currentPublisher.customizationsList);
    }
  }, [props.currentPublisher]);

  function editPublisher () {
    setEditingPublisher(true);
  }

  function updatePublisher () {
    const curPubData = {...props.currentPublisher, ...publisherEditingData};
    const {
      companyFullname,
      logo,
      url,
      cnpj,
      active
    } = curPubData;

    // const companyName = editPublisherName.current.value;
    // const logo = editPublisherLogo.current.value;
    // const url = editPublisherURL.current.value;
    // const cnpj = editPublisherDoc.current.value;
    // const active = !!editPublisherStatus.current.checked;

    setIsLoading(true);
    const newPublisherData = {
      id: props.currentPublisher.id,
      companyFullname,
      companyName: companyFullname,
      logo,
      url,
      cnpj,
      active,
      customizationsList: publisherCustomizations
    };

    let theValue = publisherCustomizations[0];
    theValue = (typeof theValue === 'string') ? JSON.parse(theValue) : theValue;

    const newCustomizations = {};

    if (theValue && theValue.name && theValue.value) {
      newCustomizations.name = theValue.name;
      newCustomizations.value = theValue.value
    } else {
      newCustomizations.name = "*";
      newCustomizations.value = (typeof theValue === 'string') ? theValue : JSON.stringify(theValue);
    }
// return;
    props.updatePublisher(
      newPublisherData,
      // we store all the customizations under the name "*"
      // [{name: '*', value: JSON.stringify(publisherCustomizations[0].value)}]
      [newCustomizations]
    ).then(res => {
      newPublisherData.customizationsList = res.map(c => c.data.createCustomization.customization);
      props.setCurrentPublisher(newPublisherData); // res.data.updatePublisher.publisher);
      setIsLoading(false);
      setEditingPublisher(false);
    }).catch(error => {
      console.error("Error: ", error);
      setIsLoading(false);
    });
  }

  const numberOfPages = (getArticleInLanguage(props.current, props.currentLang.value).pages || []).length;

  return (
    <main className="maineditor-container" data-has-content={!!props.current}>
      <div className={''} />
      <div>
        {
          props.current
          ? <Arbitragem
              content={props.current}
              updateContent={props.updateContent}
              currentLang={props.currentLang}
              settings={props.settings}
            />
          : <>
              <div style={{float: 'left'}}>
                <IconSubdirectoryArrowLeft style={{width: '120px', height: '120px', opacity: .3, marginTop: '10px'}} />
              </div>
              <div style={{float: 'left', marginLeft: '20px', lineHeight: '40px', borderLeft: 'solid 1px #ccc', paddingLeft: '35px'}}>
                - <strong>Criar</strong> um novo artigo<br/>
                - <strong>Importar</strong> um conteúdo a partir de uma URL<br/>
                - <strong>Abrir</strong> um artigo existente<br />
                - <strong>Abrir</strong> a galeria de imagens<br />
                - <strong>Editar</strong> verticais<br />
                - <strong>Ajustar</strong> configurações e padrões existentes<br />
              </div>
            </>
        }
      </div>
      <footer className="footer">
        <div className="publisher">
          {
            props.isAdmin
            ? <strong style={{color: 'red'}}>| Admin Access |&nbsp;</strong>
            : null
          }
          Publisher: 
          {
            props.currentPublisher
              ? <span>
                  {props.currentPublisher.companyName}
                  <IconButton
                    color="inherit"
                    onClick={editPublisher}
                    edge="start"
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              : <span>Nenhum selecionado</span>
          }
        </div>
        <div className="article-short-info">
          {
            props.current
            ? <>
                {
                  props.isSaving
                  ? <span className="saving-label">
                      <CircularProgress size={20} />
                      <span>{ typeof props.isSaving === 'string' ? props.isSaving : 'Salvando...' }</span>
                      |
                    </span>
                  : ''
                }
                {
                  numberOfPages
                }
                &nbsp;páginas
                &nbsp;| {props.currentLang.value}
              </>
            : null
          }
        </div>
      </footer>
      <Modal
        title={"Editar dados do Publisher"}
        maxWidth='lg'
        open={!!editingPublisher}
        loading={isLoading}
        onClose={_ => setEditingPublisher(false)}
        onSave={updatePublisher}
        saveLabel="Salvar"
        body={<PublisherEditor
          publisher={props.currentPublisher}
          settings={props.settings}
          onChange={data => {
            setPublisherEditingData(data);
          }}
          onCustomizationChange={customizations => {
            const idx = publisherCustomizations.findIndex(cust => cust.name === '*');
            const tmpList = [...publisherCustomizations];
            tmpList[idx < 0 ? 0 : idx] = customizations;
            setPublisherCustomizations(tmpList);
          }}
        />}
        // body={<div>
        //   <TextField
        //     id="publisher-name"
        //     label="Nome"
        //     variant="outlined"
        //     maxLength="60"
        //     fullWidth
        //     inputRef={editPublisherName}
        //     autoFocus
        //     defaultValue={props.currentPublisher ? props.currentPublisher.companyName : ''}
        //   />
        //   <br /><br />
        //   <TextField
        //     id="publisher-doc"
        //     label="CNPJ"
        //     variant="outlined"
        //     fullWidth
        //     inputRef={editPublisherDoc}
        //     defaultValue={props.currentPublisher ? props.currentPublisher.cnpj : '' }
        //   />
        //   <br /><br />
        //   <TextField
        //     id="publisher-logo"
        //     label="Logo"
        //     variant="outlined"
        //     inputProps={{type: 'url'}}
        //     fullWidth
        //     inputRef={editPublisherLogo}
        //     defaultValue={props.currentPublisher ? props.currentPublisher.logo : '' }
        //   />
        //   <br /><br />
        //   <TextField
        //     id="publisher-url"
        //     label="URL"
        //     variant="outlined"
        //     inputProps={{type: 'url'}}
        //     fullWidth
        //     inputRef={editPublisherURL}
        //     defaultValue={props.currentPublisher ? props.currentPublisher.url : '' }
        //   />
        //   <br /><br />
        //   <label htmlFor="publisher-status">Status</label>
        //   <Switch
        //     defaultChecked={props.currentPublisher ? props.currentPublisher.active : false}
        //     id="publisher-status"
        //     name="publisher-status"
        //     value="checkedB"
        //     color="primary"
        //     inputRef={editPublisherStatus}
        //     inputProps={{ 'aria-label': 'status' }}
        //   />
        // </div>}
      />
    </main>
  );
}

export default MainEditor;
