import React from 'react';
import ContactsIcon from '@material-ui/icons/Contacts';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { FixedSizeList as List } from 'react-window';
import uniqId from 'uuid/v4';

import { queries } from '../../graphql';
import { getPublisherData } from '../../resources/utils';

import Modal from '../modal';
import './index.scss';

function ChangePublisher (props) {
  const title = 'Selecionar Publisher';

  const [open, setOpen] = React.useState(false);
  const [addingPublisher, setAddingPublisher] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState(null);

  const editPublisherName = React.useRef('editPublisherName');
  const editPublisherDoc = React.useRef('editPublisherDoc');
  const editPublisherLogo = React.useRef('editPublisherLogo');
  const editPublisherURL = React.useRef('editPublisherURL');
  const editPublisherStatus = React.useRef('editPublisherStatus');
  
  React.useEffect(() => {
    // if (typeof props.currentPublisher === 'string') {
    //   setOpen(true);
    //   goFetchPublisherData(props.currentPublisher)
    //   .then(publisherData => {
    //     props.setCurrentPublisher(publisherData.data.publisher);
    //     setOpen(false);
    //   })
    //   .catch(error => {
    //     alert('Failed loading publisher data!');
    //     console.error(error);
    //   });
    //   return;
    // }

    if (!props.currentPublisher) {
      setOpen(true);
      goFetchTheList();
    } else {
      setOpen(false);
    }
  }, [props.currentPublisher]);

  // function goFetchPublisherData (id) {
  //   setLoading(true);
  //   return getPublisherData(id);
    // return new Promise((resolve, reject) => {
    //   queries.getPublisher(id)
    //     .then(result => {
    //       resolve(result);
    //     })
    //     .catch(error => {
    //       reject(error);
    //     })
    //     .finally(_ => {
    //       setLoading(false);
    //     });
    // });
  // }

  async function goFetchTheList () {
    setLoading(true);
    queries.getPublishers()
      .then(result => {
        setList(result.data.publishers.nodes);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  function onNewPublisherSave () {
    if (addingPublisher) {
      setLoading(true);
      const publisherData = {
        id: uniqId(),
        companyName: editPublisherName.current.value,
        cnpj: editPublisherDoc.current.value,
        logo: editPublisherLogo.current.value,
        url: editPublisherURL.current.value,
        active: !!editPublisherStatus.current.checked
      };

      props.updatePublisher(publisherData)
        .then(_ => {
          setLoading(false);
          setAddingPublisher(false);
          setOpen(false);
        })
        .catch(error => {
          setLoading(false);
          alert("Falha ao tentar criar o novo publisher!");
          console.error('Failed creating the publisher in the database!', error);
        });
    }
    setAddingPublisher(true);
  }

  if (props.currentUser && props.currentUser.user) {
    return null;
  }

  return (
    <>
      <ListItem
        button
        key={title}
        onClick={_=>{
          setOpen(true);
          setList(null);
          setLoading(true);
          goFetchTheList();
        }}>
        <ListItemIcon><ContactsIcon /></ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      <Modal
        title={title}
        body={
          addingPublisher
          ? <>
              <TextField
                id="publisher-name"
                label="Nome"
                variant="outlined"
                maxLength="60"
                fullWidth
                inputRef={editPublisherName}
                autoFocus
                defaultValue={''}
              />
              <br/><br/>
              <TextField
                id="publisher-doc"
                label="CNPJ"
                variant="outlined"
                maxLength="60"
                fullWidth
                inputRef={editPublisherDoc}
                autoFocus
                defaultValue={''}
              />
              <br/><br/>
              <TextField
                id="publisher-logo"
                label="Logo"
                variant="outlined"
                inputProps={{type: 'url'}}
                fullWidth
                inputRef={editPublisherLogo}
                defaultValue={''}
              />
              <br/><br/>
              <TextField
                id="publisher-url"
                label="URL"
                variant="outlined"
                inputProps={{type: 'url'}}
                fullWidth
                inputRef={editPublisherURL}
                defaultValue={''}
              />
              <br/><br/>
              <label htmlFor="publisher-status">Status</label>
              <Switch
                defaultChecked={true}
                id="publisher-status"
                name="publisher-status"
                value="checkedB"
                color="primary"
                inputRef={editPublisherStatus}
                inputProps={{ 'aria-label': 'status' }}
              />
          </>
          : <div>
            {
              list
              ? <List
                  height={250}
                  itemCount={list.length}
                  itemSize={35}
                  width={'100%'}
                >
                  {
                    ({ index, style }) => {
                      return <ListItem
                        button
                        style={{...style}}
                        className={props.currentPublisher && props.currentPublisher.id === list[index].id ? 'cur-publisher-selected' : ''}
                        onClick={event => {
                          props.setCurrentPublisher(list[index]);
                          setOpen(false);
                        }}
                      >
                        <div className="publisher-logo" style={{backgroundImage: `url(${list[index].logo})`}} />
                        {list[index].companyName}
                      </ListItem>
                    }
                  }
                </List>
              : <span>Carregando...</span>
            }
          </div>
        }
        loading={loading}
        maxWidth='sm'
        open={open}
        saveLabel={
          addingPublisher
            ? <>Salvar</>
            : <><PersonAddIcon />&nbsp;&nbsp;&nbsp;Criar novo</>
        }
        onSave={onNewPublisherSave}
        onClose={_=>{setOpen(false); setAddingPublisher(false);}}
      />
    </>
  );
}

export default ChangePublisher;
