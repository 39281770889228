import React from 'react';
import SanitizedHTML from 'react-sanitized-html';
import Block from '../index.js';
import Modal from '../../modal';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte';
import './index.scss';

function Paragraph (props) {

  const [ editing, setEditing ] = React.useState(false);
  const [ curHTMLContent, setCurHTMLContent ] = React.useState('');

  function getContentProps (s){
    const d = document.createElement('div');
    d.innerHTML = s || '';
    s = d.outerText;
    s = s.replace(/(^\s*)|(\s*$)/gi, "");
    s = s.replace(/[ ]{2,}/gi, " ");
    s = s.replace(/\n /, "\n");
    const words = s.split(' ').length;
    const letters = s.length
    return {
      words,
      letters,
      images: Array.from(d.querySelectorAll('img')).map(img => img.getAttribute('src')),
      links: d.querySelectorAll('a').length
    };
  }

  function onInfo () {
    const content = props.data.content;
    const data = getContentProps(content);
    return <div>
      Página: <strong>{props.pageI + 1}</strong><br/>
      Quantidade de palavras: <strong>{data.words}</strong><br/>
      Quantidade de letras: <strong>{data.letters}</strong><br/>
      Quantidade de Links: <strong>{data.links}</strong><br/>
      Imagens: <strong>{data.images.length}</strong><br/>
      {
        data.images.length
        ? <ul>
          {
            data.images.map((imgSrc, i) => <li key={i}>{imgSrc}</li>)
          }
        </ul>
        : null
      }
    </div>
  }

  function onEdit () {
    const contentHTML = convertFromHTML(props.data.content || '');
    const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
    const content = JSON.stringify(convertToRaw(state));
    setEditing(content);
  }

  function onEdited (event) {
    props.onEdit({ content: curHTMLContent });
    setEditing(false);
  }

  function onEditorChange (data /* , a, b */) {
    setCurHTMLContent(stateToHTML(data.getCurrentContent()));
  }

  return <>
    <Block
      {...props}
      onEdit={onEdit}
      onInfo={onInfo}
      editOnDblClick
    >
      <SanitizedHTML
        className="unselectable"
        html={ props.data.content || ''}
        allowedTags={[
          'div', 'span', 'a',
          'img', 'figure', 'figcaption', 'blockquote',
          'table', 'tr', 'td', 'tbody', 'thead',
          'p', 'b', 'i', 'strong', 'em', 'u',
          'h1', 'h2', 'h3', 'h4', 'h5', 'h6'
        ]}
      />
    </Block>
    {
      editing
      ? <Modal
          title='Editando...'
          body={<div>
            <MUIRichTextEditor
              label=""
              controls={[
                "title",
                "bold",
                "italic",
                "underline",
                // "strikethrough",
                // "highlight",
                "undo",
                "redo",
                "link",
                // "image",
                "numberList",
                "bulletList",
                "quote",
                // "code",
                "clear",
                // "save"
              ]}
              // onSave={data => {
              //   stateToHTML(JSON.parse(data));
              //   console.log(stateToHTML, stateToHTML(JSON.parse(data)));
              // }}
              value={editing}
              autoFocus
              onChange={onEditorChange}
            />
          </div>}
          maxWidth='lg'
          onSave={onEdited}
          open={!!editing}
          saveLabel="Aplicar"
          onClose={_=>{setEditing(false)}}
        />
      : null
    }
  </>;
}

export default Paragraph;
