import React from 'react';
import IconLoupe from '@material-ui/icons/Loupe';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Modal from '../modal';
import { createArticle } from '../../resources/data-structures';
import { Logger } from '../../resources/utils';
// import gql from 'graphql-tag';
import { mutations } from '../../graphql';

// import { useMutation } from '@apollo/react-hooks';
import './index.scss';

// const CREATE_ARTICLE = gql(`
//   mutation createArticle($title: String, $date: AWSDateTime!, $articlePublisherId: ID){
//     createArticle(input: {
//       title: $title
//       date: $date
//       articlePublisherId: $articlePublisherId
//     }) {
//       id
//     }
//   }
// `);

function New (props) {
  // const [createArticleGraphQL /* , { data } */] = useMutation(CREATE_ARTICLE);
  const modalTitle = 'Novo';
  const logger = new Logger('New Article Tool');
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  
  React.useEffect(() => {}, []);

  function onSave () {
    setLoading(true);
    const articleData = createArticle(
      props.settings,
      {
        title,
        publisherId: props.currentPublisher.id
      }
    );
    logger.info('Created local article');

    props.setCurrentArticle(articleData);
    props.setSaving(true);
    
    mutations.createArticle(articleData)
    // createArticleInDB(articleData)
      .then(_ => {
        logger.info('Created article in database');
        props.setSaving(false);
      })
      .catch(error => {
        alert("Falha ao tentar salvar o artigo na base de dados!");
        logger.error('Failed creating the article in the database!', error);
      });

    setLoading(false);
    setOpen(false);
    logger.info('Reseting to the default language');
    props.setCurrentLang(props.settings.defaultLanguage);

    // mutations.createArticle(
    //   title,
    //   (new Date()).toISOString(),
    //   props.currentPublisher.id
    // ).then(id => {
    //   const articleData = createArticle(
    //     props.settings,
    //     {
    //       id,
    //       title
    //     }
    //   );
  
    //   props.setCurrentArticle(articleData);
    //   setLoading(false);
    //   setOpen(false);
    //   props.setCurrentLang(props.settings.defaultLanguage);
    // })
    // .catch(error => {
    //   console.error(error);
    //   alert("Ocorreu um erro ao criar o novo artigo!\n" + error.message)
    //   setLoading(false);
    // });



    // createArticleGraphQL({
    //   variables: {
    //     title,
    //     date: (new Date()).toISOString(),
    //     articlePublisherId: "73f379cc-f0b2-4dd5-8200-e0df17801790"
    //   }
    // });
  }

  // function createArticleInDB (articleData) {
  //   // TODO: add mutation here, to create the empty article
  //   return new Promise(async (resolve, reject) => {
  //     // - upsert behaviors
  //     // - upsert article
  //     // - insert tags for article
  //     let insertedBehavior = await new Promise((resolve, reject) => {
  //       mutations.createBehaviors(articleData.behaviors)
  //         .then((result) => {
  //           resolve(result);
  //         }).catch((err) => {
  //           logger.error(err);
  //           reject(err);
  //         });
  //     });

  //     if (insertedBehavior.data) {
  //       insertedBehavior = insertedBehavior.data.upsertBehavior.behavior.id
  //     } else {
  //       reject(insertedBehavior);
  //     }

  //     await mutations.createArticle(articleData);
  //     resolve();
  //   });
  // }

  return (
    <>
      <ListItem
        button
        key={modalTitle}
        disabled={!props.currentPublisher}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><IconLoupe /></ListItemIcon>
        <ListItemText primary={modalTitle} />
      </ListItem>
      <Modal
        title={modalTitle}
        loading={loading}
        body={<div>
          <TextField
            // id="outlined-name"
            label="Título"
            fullWidth
            // name="url"
            onChange={event => {
              setTitle(event.target.value);
            }}
            // disabled={loading}
            inputProps={{
              name: 'title',
              // placeholder: 'https://page.com/feed',
              autoComplete: 'off'
            }}
            // className={classes.textField}
            value={title}
            margin="normal"
            variant="outlined"
          />
        </div>}
        maxWidth='sm'
        open={open}
        onSave={onSave}
        saveLabel="Criar"
        onClose={_=>{setOpen(false)}}
      />
    </>
  );
}

export default New;
