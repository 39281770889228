import packageJson from '../package.json';
import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import defaultSettings from './resources/default-settings';
import MainEditor from './components/main-editor';
import Sidebar from './components/sidebar';
import LoginForm from './components/login-form';

import { ApolloProvider } from 'react-apollo';
import GraphQLClient, { mutations } from './graphql';
import {
  getUserFromSession,
  getPublisherData,
  updatePublisher,
  isValidForPublish
} from './resources/utils';

import './App.scss';

import useStyles from './app-style';

console.log('%cEditor version: v' + packageJson.version, 'color: blue; font-size: 20px; background-color: white;');

export default function App () {
  const classes = useStyles();

  // app title and drawer
  const [appTitle, setAppTitle]                 = React.useState('');
  const [open, setOpen]                         = React.useState(false);
  
  // user authentication
  const [currentUser, setCurrentUser]           = React.useState(getUserFromSession());
  const [currentPublisher, setCurrentPublisher] = React.useState(null);
  const [settings, setSettings]                 = React.useState(defaultSettings);

  // article
  const [hasChange, setHasChange]               = React.useState(false);
  const [isSaving, setSaving]                   = React.useState(false);
  const [currentLang, setCurrentLang]           = React.useState(defaultSettings.defaultLanguage);
  const [currentArticle, setCurrentArticle]     = React.useState(null);

  settings.set = (key, value) => {
    setSettings(oldValues => ({
      ...oldValues,
      [key]: value,
    }));
  };

  const updateContent = (newContent) => {
    setTheCurrentArticle(newContent);
  };

  const setAndStoreCurrentPublisher = (publisher) => {
    setCurrentPublisher(publisher);
    window.localStorage.setItem('currentPublisher', JSON.stringify(publisher));
  };

  const setTheCurrentArticle = (data) => {
    if (currentArticle && hasChange) {
      if (!data || currentArticle.id !== data.id) {
        // user is going to a different article
        if (!window.confirm('Há alterações não publicadas no artigo atual (salvas apenas em sua máquina). Tem certeza que deseja descartar estas alterações para trabalhar em outro conteúdo?')) {
          return;
        }
      }
    }

    Promise.all([
      new Promise(resolve => {
        if (data) {
          window.localStorage.setItem('currentlyEditing', JSON.stringify(data));
        } else {
          window.localStorage.removeItem('currentlyEditing');
        }
        resolve();
      }),
      new Promise(resolve => {
        window.localStorage.setItem('currentlyEditingHasChange', data ? 1 : 0);
        resolve();
      })
    ]).then(_ => {
      setHasChange(true);
      setTheCurrentLang(currentLang || settings.defaultLanguage);
      setCurrentArticle(data ? {...data} : null);
    });
  };

  const setTheCurrentUser = async (data) => {
    const token = data.token;

    if (token) {
      window.sessionStorage.setItem('publishEditorK', token);
      window.sessionStorage.setItem('currentUser', JSON.stringify(data));
      if (data.publisherId) {
        const publisherData = await getPublisherData(data.publisherId);
        setCurrentPublisher(publisherData);
      }
      setCurrentUser(data);
      // re-authenticate every 30 minutes
      scheduleReauth();
    }
  };

  const setUnchanged = () => {
    window.localStorage.setItem('currentlyEditingHasChange', 0);
    setHasChange(false);
  };

  const publishArticle = (status = true) => {

    const isValid = isValidForPublish(currentPublisher, currentArticle);
    if (isValid !== true) {
      return Promise.reject(isValid);
    }

    function errorAlert (error) {
      alert('Falha ao tentar salvar o artigo!');
      console.error(error);
    }

    currentArticle.status = status;
    return mutations.upsertDeepArticleData(currentArticle, settings)
      .then((result) => {
        console.log("Article saved: ", result);
        mutations.upsertDeepArticleContent(currentArticle, settings)
          .then((result) => {
            console.log("Article's content saved", result);
            setUnchanged();
          })
          .catch(error => {
            errorAlert(error);
          });
      }).catch((error) => {
        errorAlert(error);
      });
  };

  const removeArticle = () => {
    return publishArticle(false); // will soft delete it
    // return new Promise((resolve, reject) => {
    //   mutations.removeArticle(currentArticle.id, settings)
    //   .then((result) => {
    //     setHasChange(false);
    //     window.localStorage.setItem('currentlyEditingHasChange', 0);
    //     setTheCurrentArticle(null);
    //     resolve(result);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
    // });
  }

  const setTheCurrentLang = (newLang) => {
    if (!currentArticle) {
      return false;
    }
    const contentInLang = currentArticle.contents.find(content => content.language === newLang.value);
    if (contentInLang) {
      setCurrentLang(newLang);
      return true;
    }
    return false;
  };

  const updateCategories = (tags) => {
    currentArticle.tags = tags;
    setTheCurrentArticle(currentArticle);
  }

  const updateBehaviors = (behaviors) => {
    currentArticle.behaviors = behaviors;
    setTheCurrentArticle(currentArticle);
  }

  const reAuthenticate = () => {
    if (!currentUser) {
      // reloads the page
      logout();
      // location.reload();
      return;
    }
    fetch(
      `${settings.serverBaseURL}re-authenticate`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          k: currentUser.token
        }) 
      }
    ).then(async res => {
      const result = await res.json();
      const token = result.data.authenticate.jwtToken;
      // setAuthenticating(false);
      if (result.status !== 'OK') {
        console.error(result.message);
        return alert("Erro ao tentar autenticar!");
      }
      currentUser.token = token;
      setTheCurrentUser(currentUser); // result.data, currentUser.user || window.sessionStorage.getItem('currentUser'));
    }).catch(async error => {
      // setAuthenticating(false);
      console.error('Failed authenticating!', error);
      return alert("Erro ao autenticar!");
    });
  };

  function logout () {
    setCurrentPublisher(null);
    setCurrentUser(null);
    window.localStorage.removeItem('currentlyEditing');
    window.sessionStorage.removeItem('publishEditorK');
    window.sessionStorage.removeItem('currentUser');
  }

  const scheduleReauth = () => {
    // re-authenticate every 30 minutes
    setTimeout(reAuthenticate, 1800 * 1000);
  }

  function isLogged () {
    return (currentUser && currentUser.name) ? currentUser : false;
  }
  
  function isAdmin () {
    return (currentUser && currentUser.name === 'admin') ? currentUser : false;
  }

  React.useEffect(_ => {
    if (currentUser) {
      // re-authenticate on (re)load
      reAuthenticate();
    }
  }, []);

  React.useEffect(_ => {
    if (!currentArticle) {
      // if we are not opening any new content, we try and look for content in localStorage
      const currentPublisher = window.localStorage.getItem('currentPublisher');
      const currentlyEditing = window.localStorage.getItem('currentlyEditing');

      if (currentPublisher) {
        setCurrentPublisher(JSON.parse(currentPublisher));

        if (currentlyEditing) {
          setCurrentArticle(JSON.parse(currentlyEditing));
          setHasChange(!!parseInt(window.localStorage.getItem('currentlyEditingHasChange'), 10));
        }
      }
    }
  }, [currentArticle]);

  const params = {
    isAdmin: isAdmin(),
    open,
    settings,
    currentPublisher,
    currentUser,
    currentLang,
    hasChange,
    current: currentArticle,
    setSaving,
    updatePublisher,
    logout,
    removeArticle,
    publishArticle,
    setCurrentPublisher: setAndStoreCurrentPublisher,
    setCurrentArticle: setTheCurrentArticle
  };

  return (
    <div className={classes.root}>
      {
        !isLogged()
        ? <LoginForm
            settings={settings}
            open={true}
            onLogin={setTheCurrentUser}
          />
        : <ApolloProvider client={GraphQLClient}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={_=>setOpen(false)}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: !open,
                  })}>
                    <MenuOpenIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={_=>setOpen(true)}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                  {appTitle}
                </Typography>
              </Toolbar>
            </AppBar>
            <Sidebar
              {...params}
              setCurrentLang={setTheCurrentLang}
              onCategoriesChange={updateCategories}
              onBehaviorsChange={updateBehaviors}
            />
            <MainEditor
              {...params}
              isSaving={isSaving}
              setAppTitle={setAppTitle}
              updateContent={updateContent}
              updatePublisher={updatePublisher}
            />
          </ApolloProvider>
      }
    </div>
  );
}
