import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';

import './index.scss';

function EditableInfo (props) {

  const [editing, setEditing] = React.useState(false);
  const [curVal, setCurVal] = React.useState(props.value);

  function onChange (event) {
    setCurVal(event.target.value);
  }

  function onDone () {
    setEditing(false);
    props.onChange(curVal);
  }

  function onAbort () {
    setEditing(false);
    setCurVal(props.value);
  }

  return <div className={"editable-info-container " + (props.className || '')}>
    {
      editing
      ? <>
          <TextField 
            placeholder={props.placeholder || ''}
            value={curVal}
            onChange={onChange}
          />
        </>
      : <>
          {
            curVal === null || curVal === false
              ? props.empty || <span>{props.placeholder}</span>
              : props.view || <span className="value-container">{curVal}</span>
          }
        </>
    }
    <div className="editable-info-btns">
      {
        props.loading
        ? <CircularProgress />
        : editing
          ? <>
              <IconButton onClick={onDone}><DoneIcon /></IconButton>
              <IconButton onClick={onAbort}><CancelIcon /></IconButton>
            </>
          : <IconButton onClick={event=>setEditing(true)}><EditIcon /></IconButton>
      }
    </div>
  </div>;
}

export default EditableInfo;
