import React /*, { useEffect, useState, useCallback, useRef }*/ from 'react';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// import Modal from '../modal';
import ImagePicker from '../image-picker';

import './index.scss';

/**
 * React component: Gallery
 * 
 * The image Gallery panel.
 * 
 * @param {Object} props Component's properties
 * @return {ReactComponent} Gallery
 */
export default function Gallery (props) {
  
  const {
    className = '',
    settings,
    // ...other
  } = props;

  const title = "Galeria de Imagens";

  return (
    <div
      className={"gallery-container " + className}
      // {...other}
    >
      <ImagePicker
        title={title}
        settings={settings}
        currentPublisher={props.currentPublisher}
        // deletable // TODO: it should be deletable
        // onSelected={selectedList => {
        //   debugger;
        //   console.log(selectedList);
        // }}
        buttonElement={
          <ListItem
            button
            key={title}
            disabled={!props.currentPublisher}
          >
            <ListItemIcon><BurstModeIcon /></ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        }
      />
    </div>
  );
}
