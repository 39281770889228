import React from 'react';
import debounce from 'debounce-promise';
import Block from '../index.js';
import TextField from '@material-ui/core/TextField';
import Modal from '../../modal';
import ImageIcon from '@material-ui/icons/PermMedia';
import UploadButton from '../../upload-button';
import './index.scss';

// const DEFAULT_URL = '/deafult-image.png';

function fetchImageOptionsFN (props, terms) {
  return new Promise((resolve, reject) => {
    fetch(`${props.settings.serverBaseURL}images?terms=${decodeURIComponent(terms)}`)
      .then(async res => {
        const list = await res.json();
        resolve(list);
      })
      .catch(err => {
        reject(err);
      });
  });
}

const fetchImageOptions = debounce(fetchImageOptionsFN, 500);

function Image (props) {

  const imgEl = React.useRef(null);
  const [url, setURL] = React.useState(props.data.url);
  const [ font, setFont ] = React.useState(props.data.font);
  const [ alt, setAlt ] = React.useState(props.data.alt);
  const [ editing, setEditing ] = React.useState(false);
  const [ urlInputChange, setURLInputChange ] = React.useState(props.data.url);
  const [ loadingThumbs, setLoadingThumbs ] = React.useState(false);
  const [ imageOptionsList, setImageOptionsList ] = React.useState(false);
  const [ tmpURL, setTmpURL ] = React.useState(null);
  const [ curThumbSelected, setCurThumbSelected ] = React.useState(null);

  React.useEffect(_ => {
    setURL(props.data.url);
    setURLInputChange(props.data.url);
  }, [props.data.url]);
  
  function onInfo () {
    const { url, data } = props.data;

    const propsKeys = (Object.keys(data || {}));

    return <div>
      Página: <strong>{props.pageI + 1}</strong><br/>
      {
        url
        ? <>
            URL: <a href={url}>{url}</a><br/><br/>
            Propriedades extras:<br/>
            <ul>
              <li>Largura original: <strong>{imgEl.current.naturalWidth}px</strong></li>
              <li>Altura original: <strong>{imgEl.current.naturalHeight}px</strong></li>
              {
                propsKeys.map(prop => {
                  return <li key={prop}>{prop}: {JSON.stringify(data[prop])}</li>
                })
              }
            </ul>
          </>
        : <>
            Edite esta imagem para definir qual a URL dela
          </>
      }
    </div>
  }

  function onEdit () {
    setTmpURL(url);
    setEditing(true);
  }

  function onEdited () {
    let tmpUrl = imageOptionsList && curThumbSelected
      ? curThumbSelected
      : isImageURL(urlInputChange) ? urlInputChange : null
      
    props.onEdit({
      url: tmpUrl,
      font: font || '',
      alt: alt || ''
    });
    setEditing(false);
  }

  function isImageURL (val) {
    return val.match(/\.(png|jpg|jpeg|gif|webp|svg)$/) && val.startsWith('http');
  }

  function handleURLInputChange (val) {
    // const val = event.target.value;
    setURLInputChange(val);

    if (val.trim().length < 4) {
      setTmpURL(null);
      setImageOptionsList(false);
      return;
    }

    if (!val.startsWith('http')) {
      setLoadingThumbs(true);
      setImageOptionsList(false);
      fetchImageOptions(props, val)
      .then(list => {
        setLoadingThumbs(false);
        setImageOptionsList(list);
      })
      .catch(error => {
        setLoadingThumbs(false);
        console.error(error);
      });
    } else {
      setLoadingThumbs(false);
      setImageOptionsList(false);
      setTmpURL(isImageURL(val) ? val : null);
    }
  }

  return <>
    <Block
      {...props}
      onEdit={onEdit}
      onInfo={onInfo}
    >
      {
        url
        ? <figure className="block-figure-container">
            <img ref={imgEl} src={ url } alt="" />
          </figure>
        : <div className="block-default-image">
            <ImageIcon />
          </div>
      }
    </Block>
    {
      editing
      ? <Modal
          title='Editando...'
          disableEnforceFocus={true}
          body={<div className="image-edit-button">
            <UploadButton
              text={tmpURL ? null : "Adicionar"}
              folder={props.publisheId}
              setURL={handleURLInputChange}
            />
            {
              imageOptionsList
              ? <div className="image-thumbs-container">
                  {
                    imageOptionsList.map(img => {
                      return <div
                        key={img.full}
                        className='image-thumb-item'
                        data-selected={curThumbSelected === img.full ? 1 : 0}
                        onClick={_ => {
                          setCurThumbSelected(img.full)
                        }}>
                        <img src={img.thumb} alt="" />
                      </div>;
                    })
                  }
                  {
                    imageOptionsList.length === 0
                    ? <div className='no-images-message'>Nenhuma imagem encontrada</div>
                    : null
                  }
                </div>
              : <div className="image-single">
                  {
                    tmpURL
                    ? <img src={tmpURL} alt="" />
                    : <div className="block-default-image">
                        <ImageIcon />
                      </div>
                  }
                </div>
            }
            
            <TextField
              label="Fonte"
              fullWidth
              // name="url"
              onChange={event => {setFont(event.target.value)}}
              // disabled={loading}
              inputProps={{
                name: 'font',
                type: 'text',
                placeholder: '',
                autoComplete: 'off'
              }}
              // className={classes.textField}
              value={font}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Descrição"
              fullWidth
              // name="url"
              onChange={event => {setAlt(event.target.value)}}
              // disabled={loading}
              inputProps={{
                name: 'alt',
                type: 'text',
                placeholder: '',
                autoComplete: 'off'
              }}
              // className={classes.textField}
              value={alt}
              margin="normal"
              variant="outlined"
            />

          </div>}
          maxWidth='lg'
          loading={loadingThumbs}
          onSave={onEdited}
          editOnDblClick
          open={!!editing}
          saveLabel="Aplicar"
          onClose={_=>{setEditing(false)}}
        />
      : null
    }
  </>;
}

export default Image;
