import React from 'react';
import LoopBehaveIcon from '@material-ui/icons/SettingsBackupRestore';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Modal from '../modal';
import './index.scss';

function Behaviors (props) {
  const modalTitle = 'Comportamentos';
  const [open, setOpen] = React.useState(false);
  const [ values, setValues ] = React.useState({
    onend: props.behaviors.onend || props.settings.defaultBehaviors.onend,
    flow: props.behaviors.flow || props.settings.defaultBehaviors.flow,
  });
  
  // React.useEffect(() => {
    // setValues(props.behaviors);
  // }, []);

  function onSave () {
    props.onSave(values);
    setOpen(false);
  }

  function handleChange (event) {
    const name = event.target.name;
    const val = event.target.value;

    setValues({
      ...values,
      [name]: val
    });
  }

  return (
    <>
      <ListItem
        button
        key={modalTitle}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><LoopBehaveIcon /></ListItemIcon>
        <ListItemText primary={'Comportamentos'} />
      </ListItem>
      <Modal
        title={modalTitle}
        body={<div className="behavior-options-container">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-age-simple">
              Ao término:
            </InputLabel>
            <Select
              value={values.onend}
              name="onend"
              onChange={handleChange}
              labelWidth={50}
              fullWidth
              inputProps={{
                name: 'onend'
              }}
            >
              <MenuItem value='LOOP'>Loop/Circular</MenuItem>
              <MenuItem value='BYCATEGORY'>Próximo artigo na mesma categoria</MenuItem>
              <MenuItem value='RANDOM'>Outro artigo aleatório</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-age-simple">
              Fluxo
            </InputLabel>
            <Select
              value={values.flow}
              name="flow"
              onChange={handleChange}
              fullWidth
              labelWidth={50}
              inputProps={{
                name: 'flow'
              }}
            >
              <MenuItem value='SEQUENCE'>Sequencial</MenuItem>
              <MenuItem value='RANDOM'>Aleatório</MenuItem>
            </Select>
          </FormControl>
        </div>}
        maxWidth='sm'
        open={open}
        onSave={onSave}
        onClose={_=>{setOpen(false)}}
      />
    </>
  );
}

export default Behaviors;
