import React           from 'react';
import Paper           from '@material-ui/core/Paper';
import uniqid          from 'uuid/v4';
import Block           from '../../blocks/';
import Title           from '../../blocks/title/';
import Paragraph       from '../../blocks/paragraph/';
import Image           from '../../blocks/image/';
import Ad              from '../../blocks/ad/';
import Related         from '../../blocks/related/';
import Video           from '../../blocks/video/';
import Nav             from '../../blocks/nav-buttons';
import Embed           from '../../blocks/embed';
import { toCanonical } from '../../../resources/utils';

import './index.scss';


function Arbitragem (props) {
  const [ curLang, setCurLang ] = React.useState(props.currentLang ? props.currentLang.value : props.settings.defaultLanguage.value);
  const [ theContent, setTheContent ] = React.useState(props.content ? props.content : null);
  const [ content, setContent ] = React.useState(theContent.contents);

  React.useEffect(_ => {
    setTheContent(props.content);
    setContent(props.content.contents);
    if (props.currentLang) {
      setCurLang(props.currentLang.value);
    }
  }, [props.content.contents, props.content, props.currentLang]);
  // }, [props.content.contents]);

  const getContentForLang = (lang, content) => {
    if (!content) {
      return { pages: [], title: ''};
    } else {
      return content.find(c => c.language === lang);
    }
  }
  const curContent = getContentForLang(curLang, content);

  const insertBlockAfter = (afterIndex, pageI, block) => {
    const firstHalf = curContent.pages[pageI].blocks.slice(0, afterIndex + 1);
    const blockSort = ((firstHalf[firstHalf.length -1] || {}).sort || 0) + 1;
    block.sort = blockSort;
    const secondHalf = curContent.pages[pageI].blocks
      .slice(afterIndex + 1)
      .map(block => {
        block.sort++;
        return block;
      });
    curContent.pages[pageI].blocks = [...firstHalf, block, ...secondHalf];
    props.updateContent(theContent);
  }

  function onRemove (block, pageI) {
    let blockSort = -1;
    curContent.pages[pageI].blocks = curContent.pages[pageI].blocks.filter(b => {
      if (b.id === block.id) {
        blockSort = block.sort;
        return false;
      }
      if (blockSort > -1 && b.sort > blockSort) {
        b.sort--;
      }
      return b;
    });
    if (!curContent.pages[pageI].blocks.length && curContent.pages.length > 0) {
      // we have more pages and the current page has no blocks
      curContent.pages.splice(pageI, 1);
    }
    props.updateContent(theContent);
  }

  function moveItem (block, pageI, diff) {
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    const nextBlock = curContent.pages[pageI].blocks[blockI + diff];

    if (nextBlock) {
      // there was a block to swap with
      const tmpBlock = {...curContent.pages[pageI].blocks[blockI]};
      curContent.pages[pageI].blocks[blockI] = nextBlock;
      curContent.pages[pageI].blocks[blockI + diff] = tmpBlock;
    } else {
      const tmpPageI = pageI + diff;
      if (diff > 0) {
        // moving down
        if (!curContent.pages[tmpPageI]) {
          curContent.pages.push({ id: uniqid(), blocks: [] });
        }
        curContent.pages[tmpPageI].blocks.unshift(curContent.pages[pageI].blocks[blockI]);
        curContent.pages[pageI].blocks.pop();
      } else {
        // moving up
        if (!curContent.pages[tmpPageI]) {
          // no page, then just do nothing
          return;
        }
        curContent.pages[tmpPageI].blocks.push(curContent.pages[pageI].blocks[blockI]);
        curContent.pages[pageI].blocks.shift();
      }
    }

    curContent.pages[pageI].blocks.forEach((block, i) => {
      block.sort = i;
    });

    props.updateContent(theContent);
  }

  function onMoveDown (block, pageI) {
    moveItem(block, pageI, 1);
  }
  function onMoveUp (block, pageI) {
    moveItem(block, pageI, -1);
  }

  function duplicate (block, pageI) {
    const newBlock = {...block};
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    newBlock.id = uniqid();
    insertBlockAfter(blockI, pageI, newBlock);
  }

  function addPageBreak (block, pageI) {
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id) + 1;
    const firstHalf = curContent.pages[pageI].blocks.slice(0, blockI);
    const secondHalf = curContent.pages[pageI].blocks.slice(blockI);

    curContent.pages[pageI].blocks = firstHalf;
    const newPage = { id: uniqid(), sort: pageI + 1, blocks: secondHalf };

    curContent.pages = [
      ...curContent.pages.slice(0, pageI + 1),
      newPage,
      ...curContent.pages.slice(pageI + 1)
    ];
    
    props.updateContent(theContent);
  }

  function addAd (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'AD',
      data: { }
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }

  function addVideo (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'VIDEO',
      data: { url: null }
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }
  
  function addRelated (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'RELATED',
      data: { }
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }
  
  function addBlock (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'PARAGRAPH',
      data: { content: '[EDITAR ESTE CONTEÚDO]'}
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }

  function addNavButtons (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'NAV',
      data: { }
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }
  
  function addImage (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'IMAGE',
      data: { url: null }
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }

  function addEmbed (block, pageI) {
    const newBlock = {
      id: uniqid(),
      kind: 'EMBED',
      data: { content: "" }
    };
    const blockI = curContent.pages[pageI].blocks.findIndex(b => b.id === block.id);
    insertBlockAfter(blockI, pageI, newBlock);
  }

  function onEdit (data, block, pageI) {
    block.data = data;
    props.updateContent(theContent);
  }

  function onTitleEdit (newTitle) {
    curContent.title = newTitle;
    curContent.canonical = toCanonical(newTitle);
    props.updateContent(theContent);
  }

  function onTophatEdit (newTophat) {
    curContent.tophat = newTophat;
    props.updateContent(theContent);
  }

  function createEvents (block, pageI) {
    return {
      onEdit       : data => onEdit(data, block, pageI),
      onRemove     : _ => onRemove(block, pageI),
      onMoveDown   : _ => onMoveDown(block, pageI),
      onMoveUp     : _ => onMoveUp(block, pageI),
      addPageBreak : _ => addPageBreak(block, pageI),
      addAd        : _ => addAd(block, pageI),
      addBlock     : _ => addBlock(block, pageI),
      addRelated   : _ => addRelated(block, pageI),
      addVideo     : _ => addVideo(block, pageI),
      addNavButtons: _ => addNavButtons(block, pageI),
      duplicate    : _ => duplicate(block, pageI),
      addImage     : _ => addImage(block, pageI),
      addEmbed     : _ => addEmbed(block, pageI)
    }
  }

  return <>
    {
      curContent
      ? <div className="editor-container">
        {
          <Title
            content={curContent.title || 'Acrescente um título'}
            tophat={curContent.tophat}
            key={curContent.id}
            onEdit={onTitleEdit}
            onTophatEdit={onTophatEdit}
          />
        }
        {
          (curContent.pages || []).sort((a, b) => {
            return (a.sort || 0) > (b.sort || 0) ? 1 : -1;
          }).map((page, pageI) => {
            return <Paper
              className={"page " + `page-sort-${pageI}`}
              key={page.id}
            >
              {
                (page.blocks || []).sort((a, b) => {
                  return (a.sort || 0) > (b.sort || 0) ? 1 : -1;
                }).map((block, blockI) => {
                  switch (block.kind.toUpperCase()) {
                    case 'AD':
                      return <Ad data={block.data} blockId={block.id} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} />;
                    case 'RELATED':
                      return <Related data={block.data} blockId={block.id} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} />;
                    case 'NAV':
                      return <Nav data={block.data} blockId={block.id} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} disableEdit />;
                    case 'IMAGE':
                      return <Image data={block.data} blockId={block.id} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} settings={props.settings} publisheId={theContent.publisherId}/>;
                    case 'VIDEO':
                      return <Video data={block.data} blockId={block.id} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} settings={props.settings} />;
                    case 'EMBED':
                      return <Embed data={block.data} blockId={block.id} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} settings={props.settings} />;
                    case 'PARAGRAPH':
                    default:
                      return <Paragraph data={block.data} key={block.id} sort={block.sort || blockI} {...createEvents(block, pageI)} pageI={pageI} />;
                  }
                })
              }
              {
                !page.blocks.length
                  ? <Block
                      key={'single-block'}
                      disableEdit
                      disableRemove={curContent.pages.length === 1}
                      disableHeader
                      {
                        ...createEvents({ id: uniqid() }, pageI)
                      }
                      pageI={pageI}
                    />
                  : null
              }
            </Paper>
          })
        }
      </div>
      : <div className="editor-container">
          <Paper
            className={"page " + `page-sort-0`}
            key={'pageless'}
          >
            <Block
              key={'single-block'}
              disableEdit
              disableRemove={true}
              disableHeader
              {
                ...createEvents({ id: uniqid() }, 0)
              }
              pageI={0}
            />
          </Paper>
        </div>
    }
  </>;
}

export default Arbitragem;
