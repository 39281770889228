import defaultSettings from './default-settings';
import Logger from './logger';
import toCanonical from './to-canonical';
import { queries, mutations } from '../graphql';

export function getPublisherData (id) {
  return queries.getPublisher(id).then(result => result.data.publisher);
}

export function getUserFromSession () {
  const sess = window.sessionStorage.getItem('currentUser');
  return sess ? JSON.parse(sess) : null;
}

export function updatePublisher (publisherData, customizations = null) {
  return new Promise((resolve, reject) => {
    mutations.updatePublisher(publisherData)
    .then((result) => {
      if (customizations) {
        mutations.removePublisherCustomizations(publisherData.id)
          .catch(_ => {
            // we don't care if it triggered an error, as it most likely happend because there was nothing to delete
          })
          .finally(_ => {
            // all customizations have been deleted
            Promise.all(customizations.map(cust => {
              return mutations.addPublisherCustomizations(publisherData.id, cust.name, cust.value);
            }))
            .then(results => {
              resolve(results);
            })
          });
      } else {
        resolve(result);
      }
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export function getArticleAddresses (publisher, article) {
  const origin = (new URL(publisher.url)).origin;

  const editorials = article.tags.filter(tag => tag.kind === 'EDITORSHIP');
  if (!editorials.length) {
    return null;
  }
  const links = {};
  article.contents.forEach(content => {
    const lang = content.language;
    links[lang] = links[lang] || [];
    editorials.forEach(ed => {
      links[content.language].push(
        `${origin}/${ed.canonical}/${content.canonical}/?lang=${lang}`
      );

      // links[content.language].push(
      //   `${origin}/api/publisher/${publisher.id}/${ed.canonical}/${content.canonical}`
      // );
    });
  });
  return links;
}

export function isValidForPublish (publisher, article) {
  if (!article.contents.length) {
    return {
      error: true,
      message: 'Este artigo parece corrompido e não possui conteúdo em nenhum idioma!'
    };
  }

  if (!getArticleAddresses(publisher, article)) {
    return {
      error: true,
      message: 'Artigo precisa ter ao menos uma vertical para publicação'
    };
  }
  return true;
}

function findArticleImage (article, lang = defaultSettings.defaultLanguage.value) {
  const content = getArticleInLanguage(article, lang);
  let found = null;
  if (content.image) {
    return content.image;
  }

  if (content.images && content.images.length) {
    return content.images[0];
  }

  for (let i = 0; i < (content.pages || []).length; i++ ) {
    found = content.pages[i].blocks.find(block => block.kind === 'IMAGE');
    if (found) {
      return found.url;
    }
  }
}

function getArticleInLanguage (article, lang = defaultSettings.defaultLanguage.value) {
  if (!article) {
    return {};
  }
  return article.contents.find(c => c.language === lang) || {};
}

function removeDiacritics (word) {
  return word
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, "");
}

export {
  toCanonical,
  findArticleImage,
  getArticleInLanguage,
  removeDiacritics,
  Logger
};
