import React from 'react';
import IconHelp from '@material-ui/icons/Help';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';

import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Modal from '../modal';
import './index.scss';

function Help (props) {
  const title = 'Ajuda';
  const [open, setOpen] = React.useState(false);
  
  React.useEffect(() => {
  }, []);

  const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiExpansionPanel);
  
  const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiExpansionPanelSummary);
  
  const ExpansionPanelDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiExpansionPanelDetails);
  
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <ListItem
        button
        key={title}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><IconHelp /></ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      <Modal
        title={title}
        body={<div>
          <ExpansionPanel square expanded={expanded === 'q1'} onChange={handleChange('q1')}>
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
              Como excluir apenas a linguagem de um artigo?
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                É possível excluir apenas uma das linguagem de um artigo, mantendo os demais idiomas.<br/>
                Para isso, abra o artigo em questão, clique no ícone <InfoIcon /> ("Informações") para ver os detalhes do artigo.<br/>
                Na tabela com a lista de idiomas e páginas, clique no ícone <DeleteIcon /> (Excluir) e confirme.<br/>
                <br/>
                <strong>IMPORTANTE:</strong> Após esta exclusão, o conteúdo naquele idioma será perdido permanentemente.<br/>
                <br/>
                <sub><em>Caso você exclua o idioma atual do artigo, a página pode ficar em branco. Basta trocar o idioma novamente para ver o conteúdo em algum dos outros idiomas disponíveis.</em></sub>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel square expanded={expanded === 'q2'} onChange={handleChange('q2')}>
            <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
              Como fazer a tradução automática de um artigo?
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                Para utilizar o <em>Google Translator</em> para traduzir automaticamente todos os parágrafos do artigo, basta:<br/>
                <br/>
                - Abrir o artigo desejado<br/>
                - Alterar o idioma do artigo para o idioma desejado<br/>
                - Selecionar a opção "Traduzir automaticamente"<br/>
                <br/>
                Caso o artigo já tenha conteúdo naquele idioma e você queira substitui-lo por um conteúdo traduzido automaticamente, será preciso excluir a linguagem primeiro, para então gerar a tradução.
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>}
        maxWidth='lg'
        open={open}
        onClose={_=>{setOpen(false)}}
      />
    </>
  );
}

export default Help;
