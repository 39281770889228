import React from 'react';
import PreviewIcon from '@material-ui/icons/FeaturedVideoOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  getArticleInLanguage,
  findArticleImage,
  getArticleAddresses
} from '../../resources/utils';

import Modal from '../modal';
import './index.scss';

function Preview(props) {
  const modalTitle = <span>Pre-visualização</span>;
  const [open, setOpen] = React.useState(false);
  const [openedSnack, setOpenedSnack] = React.useState(false);

  React.useEffect(() => {
  }, []);

  function getArticleDetails (article, settings) {
    if (!article) {
      return null;
    }

    const data = {};
    // data.link = `${settings.readerServerBaseURL}${article.publisherId}/${article.id}?page=1`
    data.source = article.source;
    const theContent = getArticleInLanguage(article, settings.defaultLanguage.value); // article.contents.find(c => c.language === settings.defaultLanguage.value) || {};
    data.title = theContent.title || '';
    data.categories = article.tags || [];
    data.pages = {};
    let page = theContent.pages ? theContent.pages[0] : null;

    if (!page) {
      return null;
    }

    const shortDesc = (page.blocks.find(b => b.kind === 'PARAGRAPH') || {data: {content: ''}}).data.content;

    data.image = findArticleImage(article, settings.defaultLanguage.value);

    const el = document.createElement('span');
    el.innerHTML = shortDesc;
    data.shortDesc = el.innerText.substring(0, 140) + '...';

    data.links = getArticleAddresses(props.currentPublisher, article);

    return data;
  }

  function dataCopy(data) {
    return event => {
      setOpenedSnack(false);
      setTimeout(_ => {
        navigator.clipboard.writeText(data)
          .then(function() {
            setOpenedSnack(true);
          }, function(err) {
            console.error(err.message);
          });
      }, 200);
      event.stopPropagation();
      event.preventDefault();
    };
  }

  const {
    settings,
    article
  } = props;

  const data = getArticleDetails(article, settings);
  const categories = (data ? data.categories : []).map(cat => cat.name);
  
  if (data) {
    data.link = data.links ? data.links[settings.defaultLanguage.value] : null;
    data.link = data.link ? data.link[0].substring(0, 30) : '';
  }

  return (
    <>
      <ListItem
        button
        key={'Exportar Native'}
        onClick={_ => {
          setOpen(true);
        }}>
        <ListItemIcon><PreviewIcon /></ListItemIcon>
        <ListItemText primary={modalTitle} />
      </ListItem>
      <Modal
        title={modalTitle}
        body={<div>
          {
            data
            ? <Card className={'card-preview'} >
                <CardHeader
                  avatar={
                    <Avatar
                      className={''}
                      title={props.currentPublisher.companyName}
                      src={props.currentPublisher.logo || "/alright-logo.jpg"}
                    >
                      {props.currentPublisher.companyName}
                    </Avatar>
                  }
                  title={data.title}
                  onClick={dataCopy(data.title)}
                  subheader={
                    <span onClick={dataCopy(categories.join(', '))}>{categories.join(', ')}</span>
                  }
                />
                <CardMedia
                  className={''}
                  style={{
                    height: 0,
                    paddingTop: '56%'
                  }}
                  onClick={dataCopy(data.image ? data.image : null)}
                  image={data.image ? data.image : null}
                  title={data.title}
                />
                <CardContent onClick={dataCopy(data.shortDesc)}>
                  {data.shortDesc}
                </CardContent>
                <CardContent>
                  <span className="preview-link" onClick={dataCopy(data.link)}>{
                    // data.link.substring(0, 30)
                    data.link
                  }...</span>
                </CardContent>
              </Card>
            : <div>
                Nenhum preview disponível ainda.
              </div>
          }
          <sub><em>Clique nas partes do card para copiar automaticamente</em></sub>
        </div>}
        maxWidth='sm'
        open={open}
        onClose={_ => { setOpen(false) }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openedSnack}
        autoHideDuration={6000}
        onClose={_=>setOpenedSnack(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Copiado para o clipboard</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            // className={classes.close}
            onClick={_=>setOpenedSnack(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
}

export default Preview;
