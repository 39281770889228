import React /*, { useEffect, useState, useCallback, useRef }*/ from 'react';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterListOutlined';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { FixedSizeList as List } from 'react-window';
// import ListItem from '@material-ui/core/ListItem';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
import Modal from '../modal';
import UploadButton from '../upload-button';
import Paginator from '../paginator';

import './index.scss';
import { Collapse } from '@material-ui/core';

const DEFAULT_PAGE_SIZE = 15;
let LAST_CURSOR = "";
let cloudinaryLoaded = false;

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

/**
 * React component: ImagePicker
 * 
 * A component for listing saved images and selecting one ore more, and to upload images, also.
 * 
 * @param {Object} props Component's properties
 * @return {ReactComponent} ImagePicker
 */
export default function ImagePicker (props) {
  const DEFAULT_BUTTON_ELEMENT = <input type="button" value="Selecione uma imagem" />

  const {
    className = '',
    // children,
    buttonElement = DEFAULT_BUTTON_ELEMENT,
    title = "Selecione uma imagem",
    currentPublisher,
    // settings = {},
    deletable = false,
    onClear = null,
    onSelected = null,
    multiple = false,
    ...other
  } = props;

  const [sortingBy, setSortingBy] = React.useState('uploaded_at,desc');
  const [dateAfterFilter, setDateAfterFilter] = React.useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = React.useState(null);
  const [isFiltering, setIsFiltering] = React.useState(null);
  const [filename, setFilename] = React.useState('');
  const [sortBy, setSortBy] = React.useState('uploaded_at');
  const [sortAs, setSortAs] = React.useState('desc');
  const [selected, setSelected] = React.useState(new Set());
  
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState(null);
  const [totalPages, setTotalPages] = React.useState(1);
  const [imagesPerPage, setImagesPerPage] = React.useState(DEFAULT_PAGE_SIZE);
  const [curPage, setCurPage] = React.useState(0);
  // const [filteredList, setFilteredList] = React.useState([]);
  const filterRef = React.useRef('filterRef');

  // const filterTagsRef = React.useRef('filterTagsRef');
  // const [currentFilter, setCurrentFilter] = React.useState('');
  // const [curTab, setCurTab] = React.useState(0);

  function goFetchTheList () {

    if (!currentPublisher || !currentPublisher.id) {
      return;
    }

    setLoading(true);
    return new Promise(async (resolve, reject) => {
      // const sort_by = 
      // const sort_direction
      const imageApiURL = `${props.settings.serverBaseURL}images/search/` +
        `?folder=${currentPublisher.id}` +
        `&sort_by=${sortBy}` +
        `&sort_direction=${sortAs}` +
        `&limit=50` +
        `&offset=${LAST_CURSOR}` +
        `&dateFrom=${dateAfterFilter ? dateAfterFilter.toISOString() : ''}` +
        `&dateTo=${dateBeforeFilter ? dateBeforeFilter.toISOString() : ''}` +
        `&filename=${filename}`
      ;

      const list = await(await fetch(imageApiURL)).json();
      // console.log('list :>> ', list.resources);
console.log(list.resources);
      setList(list.resources || []);
      setTotalPages(list.total / imagesPerPage);
      setLoading(false);
      
      LAST_CURSOR = list.next_cursor || "";
      
      if (list.error) {
        reject(list.error);
      } else {
        resolve(list.resources);
      }
    });
  }

  // React.useEffect(() => {
  //   goFetchTheList();
  // }, [curPage, articlesPerPage, currentFilter, currentPublisher]);

  function onSortChange (event) {
    const val = event.target.value;
    const [sortBy, sortAs] = val.split(',');
    setSortingBy(val);
    setSortBy(sortBy);
    setSortAs(sortAs);
  }

  function onNameFilterChange (event) {
    setFilename(event.target.value);
  }

  function onDateAfterChange (val) {
    setDateAfterFilter(val);
  }

  function onDateBeforeChange (val) {
    setDateBeforeFilter(val);
  }

  function selectImage (resource) {

    if (!onSelected) {
      return;
    }

    setSelected(prevData => {
      let curSelected = [];
      if (multiple) {
        curSelected = new Set([...prevData]);

        if (curSelected.has(resource.public_id)) {
          curSelected.delete(resource.public_id);
        } else {
          curSelected.add(resource.public_id);
        }
      } else {
        curSelected = new Set();
        if (!prevData.has(resource.public_id)) {
          curSelected.add(resource.public_id);
        }
      }

      return curSelected;
    });
  }

  function onModalSave () {
    const selectedList = []
    selected.forEach(itemId => {
      selectedList.push(list.find(item => item.public_id === itemId));
    });
    
    if (onSelected) {
      onSelected(selectedList);
    }
    onClose();
  }

  function onClose () {
    setSelected(new Set([]));
    setOpen(false);
  }

  return (
    <div
      className={"imagePicker-comp-container " + className}
      {...other}
    >
      <span onClick={event=>{
        setLoading(true);
        setOpen(true);
        goFetchTheList();
      }}>{buttonElement}</span>
      <Modal
        title={title}
        loading={loading}
        maxWidth='xl'
        open={open}
        invalid={!(onSelected && selected.size)}
        disableEnforceFocus={true}
        cancelLabel={onSelected ? "Cancelar" : "Fechar"}
        saveLabel={ `Selecionar${multiple ? ` (${selected.size})` : ""}` }
        onSave={onSelected ? onModalSave : null}
        onCancel={(event, next) => {
          console.log('clicked the cancel button');
          next();
        }}
        onClose={onClose}
        controlls={
          <>
            {
              onClear
              ? <Button onClick={event => {onClear(); setOpen(false); }} color="secondary">Limpar</Button>
              : null
            }
            {/* <Paginator
              curPage={curPage}
              pageSize={imagesPerPage}
              pageSizes={[15, 30, 60, 100]}
              numberOfPages={totalPages}
              onPageSizeChange={setImagesPerPage}
              onChange={setCurPage}
            />
            {
              // TODO: it's now still impossible to delete images
              //? Reference for documentation on that: https://cloudinary.com/documentation/image_upload_api_reference#destroy_method
              deletable && selected.size
                ? <Button color="secondary" style={{marginLeft: 'auto'}}>
                    Excluir ({selected.size})
                  </Button>
                : null
            } */}
          </>
        }
        body={<div className={"image-picker-container"}>
          {/* <IconButton className="filter-btn" onClick={_ => setIsFiltering(!isFiltering)}>
            <FilterIcon />
          </IconButton> */}
          {
            currentPublisher
            ? <>
                {/* <AppBar position="static" className="tabs-bar">
                  <Tabs value={curTab} onChange={handleTabChange} aria-label="simple tabs example">
                    <Tab label="Minhas Imagens" {...a11yProps(0)} />
                    <Tab label="Nova Imagem" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={curTab} index={0}> */}
                <Collapse in={isFiltering}>
                  <div className="image-filters-container">
                    <TextField ref={filterRef} label="Nome" value={filename} onChange={onNameFilterChange} />

                    {/* <span>Ordenar por: </span> */}
                    <Select
                      className="filter-select"
                      value={sortingBy}
                      label="Ordenar por"
                      onChange={onSortChange}
                      // input={<FilledInput name="age" id="filled-age-simple" />}
                    >
                      <MenuItem value="uploaded_at,desc"><UpIcon /> Data de upload</MenuItem>
                      <MenuItem value="uploaded_at,asc"><DownIcon /> Data de upload</MenuItem>
                      <MenuItem value="filename,desc"><UpIcon /> Nome</MenuItem>
                      <MenuItem value="filename,asc"><DownIcon /> Nome</MenuItem>
                    </Select>

                    {/* <div className="upload-"> */}
                      <span>Upload feito</span>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          // disableToolbar
                          // variant="inline"
                          format="dd/MM/yyyy"
                          // margin="normal"
                          label="depois de..."
                          disableFuture
                          showTodayButton
                          clearable
                          todayLabel="Hoje"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          value={dateAfterFilter}
                          onChange={onDateAfterChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        <KeyboardDatePicker
                          // disableToolbar
                          // variant="inline"
                          format="dd/MM/yyyy"
                          // margin="normal"
                          label="antes de..."
                          disableFuture
                          showTodayButton
                          clearable
                          todayLabel="Hoje"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          value={dateBeforeFilter}
                          onChange={onDateBeforeChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>

                      <Button onClick={goFetchTheList} variant="contained" >
                        Aplicar
                      </Button>
                    </div>
                  </Collapse>
                  {/* </div> */}
                  <div className="gallery-list-container">
                    <div className="gallery-add-image-container">
                      <UploadButton
                        text={"Adicionar"}
                        folder={currentPublisher.id}
                        // inline={cloudinaryContainer.current}
                        // inline={".cloudinary-widget-container"}
                        // open
                        button={<div className="gallery-add-image-btn">
                          <AddIcon />
                        </div>}
                        setURL={_ => {
                          setTimeout(goFetchTheList, 1000);
                        }}
                        multiple={multiple}
                      />
                    </div>
                    {
                      (console.log(list) || list && list.length)
                        ? list.map((resource, i) => {
                            const url = resource.url.replace('image/upload/', 'image/upload/h_160/')
                            return <div
                              key={`${url}-${i}`}
                              data-key={`${url}-${i}`}
                              height={250}
                              className="gallery-list-item"
                              width={'100%'}
                              data-can-select={onSelected ? '1' : null}
                              data-selected={selected.has(resource.public_id) ? '1' : null}
                              onClick={_ => selectImage(resource)}
                            >
                              <img src={url} alt="" />
                            </div>;
                          })
                        : list
                          ? null
                          : <span>Carregando lista...</span>
                    }
                  </div>
                {/* </TabPanel> */}
                {/* <TabPanel value={curTab} index={1}>
                  {
                    cloudinaryLoaded || curTab === 1
                    ? <>
                        <div className="cloudinary-widget-container"></div>
                        <UploadButton
                          text={"Adicionar"}
                          folder={currentPublisher.id}
                          // inline={cloudinaryContainer.current}
                          inline={".cloudinary-widget-container"}
                          // open
                          setURL={list => {
                            debugger;
                            handleTabChange(null, 0);
                          }}
                          multiple
                        />
                      </>
                    : null
                  }
                </TabPanel> */}
            </>
            : null
          }
        </div>}
      />
    </div>
  );
}
