import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import './index.scss';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          disabled={props.disabled}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let lastOpenStatus = false;

export default function CustomizedDialogs (props) {
  // const [open, setOpen] = React.useState(false);
  const {
    title,
    body,
    open,
    cancelLabel,
    saveLabel,
    onSave,
    onClose,
    loading,
    maxWidth = 'lg',
    overflow = 'auto',
    onCancel = null,
    disableEnforceFocus = false,
  } = props;

  const onCloseEvent = event => {
    if (onClose) {
      onClose(event);
    }
    setTimeout(_ => {
      document.body.style.overflow = 'auto';
    }, 500);
  };

  React.useEffect(() => {
    if (!open && lastOpenStatus) {
      // has just closed
      onCloseEvent();
    }
    lastOpenStatus = open;
  }, [open]);

  return (
    <Dialog
      onClose={onCloseEvent}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={"dialog-container overflow-" + (overflow)}
      disableEnforceFocus={disableEnforceFocus}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onCloseEvent}
        disabled={loading || props.locked}>
        {title}
      </DialogTitle>
      <LinearProgress variant="indeterminate" style={{visibility: loading ? 'visible' : 'hidden'}}/>
      <DialogContent
        dividers
        style={{
          overflow
        }}
      >
        {body}
      </DialogContent>
      <LinearProgress variant="indeterminate" style={{visibility: loading ? 'visible' : 'hidden'}}/>
      <DialogActions className="modal-footer">
        <div className="controlls-container">
          {props.controlls || null}
        </div>
        <div className="buttons-container">
          <Button
            onClick={event => {
              if (onCancel) {
                onCancel(event, onCloseEvent);
              } else {
                onCloseEvent(event);
              }
            }}
            disabled={loading || props.locked}
            color={onSave ? "secondary" : "primary"}
          >
            { cancelLabel || 'Fechar' }
          </Button>
          {
            onSave
            ? <Button
                onClick={onSave}
                variant="contained"
                disabled={loading || props.invalid}
                color="primary">
              { saveLabel || 'Salvar' }
            </Button>
            : null
          }
        </div>
      </DialogActions>
    </Dialog>
  );
}
