import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.scss';

function Remove (props) {
  const [removing, setRemoving] = React.useState(false);
  
  React.useEffect(() => {
  }, []);

  function removeArticle () {
    if (!window.confirm('Tem certeza que quer excluir/despublicar este conteúdo?')) {
      return false;
    }
    setRemoving(true);
    props.removeArticle()
      .then(_ => {
        setRemoving(false);
      })
      
      .catch(error => {
        setRemoving(false);
        alert('Erro ao tentar excluir!');
        console.error(error);
      });
  }

  return (
    <ListItem
      button
      key={'Excluir'}
      className={removing ? 'publishing-menu' : ''}
      onClick={removeArticle}
    >
      <ListItemIcon>
        {
          removing
            ? <span className='loading-icon'><CircularProgress size={20} /></span>
            : <DeleteIcon style={{ color: '#900' }} />
        }
      </ListItemIcon>
      <ListItemText primary={removing ? 'Excluindo...' : 'Excluir/Despublicar'} />
    </ListItem>
  );
}

export default Remove;
