import React from 'react';
import Block from '../index.js';
import './index.scss';

function Related (props) {
  return <>
    <Block
      {...props}
      disableEdit
      // onInfo={onInfo}
      // onEdit={onEdit}
    >
      <div className='block-related-container'>
        <h3>Links Relacionados</h3>
        <div className="related-placeholder"></div>
        <div className="related-placeholder"></div>
      </div>
    </Block>
  </>;
}

export default Related;
