import React from 'react';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Block from '../index.js';
import Modal from '../../modal';
import './index.scss';

function Ad (props) {
  const [ editing, setEditing ] = React.useState(false);

  function onInfo () {
    return <span>Uma publicidade a ser carregada entre os parágrafos dentro de uma mesma página</span>
  }

  function onEdit () {
    setEditing(true);
  }

  function onEdited (event) {
    props.onEdit({  }); // TODO: add settings here, for the Ad
    setEditing(false);
  }

  return <>
    <Block
      {...props}
      onInfo={onInfo}
      onEdit={onEdit}
    >
      <div className='block-ad-container'>
        <MoneyIcon />
      </div>
    </Block>
    <Modal
      title='Editando...'
      maxWidth='xl'
      onSave={onEdited}
      open={!!editing}
      saveLabel="Aplicar"
      onClose={_=>{setEditing(false)}}
      body={<div>
        TODO: Aqui, poderão ser definidos meta-dados para esta publicidade.
      </div>}
    >

    </Modal>
  </>;
}

export default Ad;
