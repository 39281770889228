import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { toCanonical } from '../../resources/utils';

const filter = createFilterOptions();

export default function AutoCompleteOrCreate (props) {

  const { list, label, labelCreate, onChange, onCreate, verticals } = props;
  let { defaultValue } = props;
  defaultValue = Array.isArray(defaultValue) ? defaultValue[0] : defaultValue;
  
  const [value, setValue] = React.useState(defaultValue ? defaultValue.name : "");
  const [open, toggleOpen] = React.useState(false);

  useEffect(() => {
    setValue("");
  }, [verticals]);

  const handleClose = () => {
    setDialogValue({
      name: '',
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      name: dialogValue.name
    });

    handleClose();
  };

  const handleAdd = event => {
    onCreate(event, dialogValue.name);
    toggleOpen(false);
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue
            });
          } else {
            setValue(newValue);
            onChange(event, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue && params.inputValue.length > 2) {
            const searchFor = toCanonical(params.inputValue);
            const found = options.find(opt => opt.canonical === searchFor);

            if (!found) {
              filtered.push({
                inputValue: params.inputValue,
                name: `Criar "${params.inputValue}"`,
              });
            } else {
              // setValue(found);
            }
          }

          return filtered;
        }}
        // id="free-solo-dialog-demo"
        options={list}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => {
          return <TextField
            {...params}
            label={label}
            variant="outlined"
          />
        }}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Criar nova vertical</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Crie uma nova vertical. Ela será usada na URL de seus artigos e também no menu para navegação.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
              label={labelCreate || "Nome"}
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={handleAdd}
            >
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
