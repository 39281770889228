import React from 'react';
import IconCategory from '@material-ui/icons/CategoryOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ChipInput from 'material-ui-chip-input'
import uniqId from 'uuid/v4';
import { queries, mutations } from '../../graphql';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import AutoCompleteOrCreate from '../autocomplete-or-create';
import { toCanonical } from '../../resources/utils';

import Modal from '../modal';
import './index.scss';

function Categories (props) {
  const modalTitle = 'Categorias, Verticais e Tags';
  const [open, setOpen] = React.useState(false);
  const [allTags, setAllTags] = React.useState([]);
  const [allCats, setAllCats] = React.useState([]);
  const [allEditorials, setAllEditorials] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [cats, setCats] = React.useState([]);
  const [edits, setEdits] = React.useState([]);
  
  React.useEffect(() => {
    let curEdits = [];
    let curTags = [];
    let curCats = [];

    (props.tags || []).forEach(tag => {
      if (tag.kind === 'TAG') {
        curTags.push(tag);
        return;
      }
      if (tag.kind === 'CATEGORY') {
        curCats.push(tag);
        return;
      }
      curEdits.push(tag);
    });

    setTags(curTags);
    setCats(curCats);
    setEdits(curEdits);
  }, [props.tags]);

  // filling the autocomplete options
  React.useEffect(() => {
    if (!props.currentPublisher || typeof props.currentPublisher === 'string') {
      return;
    }
    queries.getAllTags(props.currentPublisher.id)
      .then(tagsList => {
        if (tagsList.data && tagsList.data.tags) {
          const tmpTagsList = [];
          const categoriesList = [];
          const editorialList = [];
          
          tagsList.data.tags.nodes.map(item => {
            if (item.kind === 'CATEGORY') {
              categoriesList.push(item);
            } else if (item.kind === 'EDITORSHIP') {
              editorialList.push(item);
            } else {
              tmpTagsList.push(item);
            }
          });

          setAllTags(tmpTagsList);
          setAllCats(categoriesList);
          setAllEditorials(editorialList);
        }
      });
  }, [props.currentPublisher, props.verticals]);

  function onSave () {
    props.onSave([...tags, ...cats, ...edits]);
    setOpen(false);
  }

  function handleTagsChange (event, values, kind = "TAG") {
    values = Array.isArray(values) ? values : [values];
    const setter = (kind === 'CATEGORY')
      ? setCats
      : (kind === 'EDITORSHIP')
        ? setEdits
        : setTags;

    setter(values.map(val => {
      if (typeof val === 'string') {
        // a new item was added to the tags list
        val = {
          id: uniqId(),
          name: val,
          kind: kind,
          publisherId: props.currentPublisher.id
        };
      }

      val.publisherId = val.publisherId || props.currentPublisher.id;
      val.kind = val.kind || 'TAG';
      val.canonical = toCanonical(val.name);
      return val;
    }));
  }

  async function handleCreateNewVertical (event, value) {
    // handleTagsChange(event, [value], "EDITORSHIP");
    const tagEditorship = {
      id: uniqId(),
      name: value,
      kind: "EDITORSHIP",
      canonical: toCanonical(value),
      publisherId: props.currentPublisher.id
    };
    await mutations.createTag(tagEditorship);
    getAllTags();
    setEdits([tagEditorship])
    return;
  }

  function getAllTags () {
    queries.getAllTags(props.currentPublisher.id)
      .then(tagsList => {
        if (tagsList.data && tagsList.data.tags) {
          const tmpTagsList = [];
          const categoriesList = [];
          const editorialList = [];
          
          tagsList.data.tags.nodes.map(item => {
            if (item.kind === 'CATEGORY') {
              categoriesList.push(item);
            } else if (item.kind === 'EDITORSHIP') {
              editorialList.push(item);
            } else {
              tmpTagsList.push(item);
            }
          });

          setAllTags(tmpTagsList);
          setAllCats(categoriesList);
          setAllEditorials(editorialList);
        }
      });
  }

  function getInput (kind, opts) {
    return <div>
      <Autocomplete
        multiple={opts.multiple !== false}
        id={kind + "-filled"}
        options={opts.options}
        defaultValue={opts.defaultValue}
        disabled={!opts.options}
        // renderOption={(opt, i) => {
        //   return <option value={i + '-' + opt.year}>{opt.title}</option>
        // }}
        getOptionLabel={opt => {
          if (opts.multiple === false) {
            opt = Array.isArray(opt) ? opt[0] : opt;
          }
          return typeof opt === 'string' ? opt : opt.name;
        }}
        autoComplete
        filterSelectedOptions
        // loading={!allTags.length}
        loading={!opts.options.length}
        loadingText="Atualizando lista"
        noOptionsText="Nenhum item encontrado"
        freeSolo
        onChange={(event, value) => {
          // if (opts.multiple === false) {
            // return;
          // }
          handleTagsChange(event, value, kind);
        }}
        renderTags={(value, getProps) => {
          return value.map((option, index) => {
            return <Chip
              variant="outlined"
              label={option.name || option}
              key={option.name}
              {...getProps({ index })}
            />
          });
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={opts.label}
            // placeholder="Selecione na lista ou digite e pressione [ENTER]"
            placeholder={opts.label}
            margin="normal"
            // autoFocus
            fullWidth
          />
        )}
      />
      <sub><em>Digite e pressione [Enter], ou selecione um item na lista. </em></sub>
    </div>;
  }

  return (
    <>
      <ListItem
        button
        key={modalTitle}
        onClick={_=>{
          setOpen(true);
        }}>
        <ListItemIcon><IconCategory /></ListItemIcon>
        <ListItemText primary={modalTitle} />
      </ListItem>
      <Modal
        title={modalTitle}
        body={
          <div className="categories-container">
            <AutoCompleteOrCreate
              list={allEditorials}
              defaultValue={edits}
              label="Vertical"
              labelCreate="Nova vertical"
              onChange={(event, value) => {
                handleTagsChange(event, value ? [value] : [], "EDITORSHIP");
              }}
              onCreate={handleCreateNewVertical}
              verticals={props.verticals}
            />
            {/* {
              getInput('EDITORSHIP', {
                options: allEditorials,
                defaultValue: edits,
                label: 'Editorias',
                multiple: false
              })
            } */}
            {
              getInput('CATEGORY', {
                options: allCats,
                defaultValue: cats,
                label: 'Categorias'
              })
            }
            {
              getInput('TAG', {
                options: allTags,
                defaultValue: tags,
                label: 'Tags'
              })
            }
          </div>
        }
        maxWidth='sm'
        open={open}
        onSave={onSave}
        onClose={_=>{setOpen(false)}}
      />
    </>
  );
}

export default Categories;
